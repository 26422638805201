import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NavComponent } from './nav/nav.component';
import { DashComponent } from './dash/dash.component';
import { CardComponent } from './card/card.component';
import { AdminRoutingModule } from './admin-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { SubByDateComponent } from './charts/sub-by-date/sub-by-date.component';
import { AuthService } from '../services/auth.service';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { DogService } from '../services/dog.service';
import { ModalService } from '../services/modal.service';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import { DogsComponent } from './dogs/dogs.component';
import { ReportsComponent } from './reports/reports.component';
import { SystemComponent } from './system/system.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AdminSpinnerComponent } from './admin-spinner/admin-spinner.component';
import { FormsTableComponent } from './forms-table/forms-table.component';
import { DataTablesModule } from 'angular-datatables';
import { FormDetailsComponent } from './form-details/form-details.component';
import { SummaryComponent } from '../summary/summary.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    NavComponent,
    DashComponent,
    CardComponent,
    SubByDateComponent,
    DogsComponent,
    ReportsComponent,
    SystemComponent,
    AdminSpinnerComponent,
    FormsTableComponent,
    FormDetailsComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    SharedModule
  ],
  providers: [ AuthService, BreadcrumbsService, DogService, ModalService, TokenStorageService, UserService],
  exports: [
    DashComponent,
    NavComponent,
    SystemComponent,
    DogsComponent,
    ReportsComponent
  ]
})
export class AdminModule { }
