import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DogService } from '../../services/dog.service';
import { OfaReportResponse } from '../../models/report.model';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  ofaform: FormGroup;
  akcform: FormGroup;
  wdcform: FormGroup;
  isWdcAdmin = false;
  isDogAdmin = false;
  formProcessing = false;
  responseString = 'Waiting...';
  responseObj: OfaReportResponse = {status: '', responseString: '', exceptionList: [], toAddedList: [], nonExistentDogsList: []};
  loadingString = 'Loading...';

  constructor(private fb: FormBuilder,
              private tokenService: TokenStorageService,
              private service: DogService) {
    this.ofaform = new FormGroup<any>({
      ofaCSV: new FormControl(null)
    });
    this.akcform = new FormGroup<any>({
      akcCSV: new FormControl(null)
    });
    this.wdcform = new FormGroup<any>({
      wdcCSV: new FormControl(null)
    });
    this.isWdcAdmin = tokenService.userHasRole('superAdmin') ||
      tokenService.userHasRole('wdcAdmin');
    this.isDogAdmin = tokenService.userHasRole('superAdmin')
  }

  onImagePickedOfa(event: Event) {
    let fileList: FileList | null = (event.target as HTMLInputElement).files;
    if ((fileList) && (fileList.length > 0)) {
      this.ofaform.controls['ofaCSV'].patchValue({image: fileList[0]});
      this.formProcessing = true;
      this.service.processOfaQuarterlyReport(fileList[0])
        .subscribe((res) => {
          this.formProcessing = false;
          this.responseObj = res;
          this.responseString = this.responseObj.responseString
        },
          (error) => {
            this.formProcessing = false;
            this.responseString = "An error occurred while processing this file";
          });
    }
  }

  onImagePickedOfaSearch(event: Event) {
    let fileList: FileList | null = (event.target as HTMLInputElement).files;
    if ((fileList) && (fileList.length > 0)) {
      this.ofaform.controls['ofaCSV'].patchValue({image: fileList[0]});
      this.formProcessing = true;
      this.service.processOfaSearchReport(fileList[0])
        .subscribe((res) => {
            this.formProcessing = false;
            this.responseObj = res;
            this.responseString = this.responseObj.responseString
          },
          (error) => {
            this.formProcessing = false;
            this.responseString = "An error occurred while processing this file";
          });
    }
  }

  onImagePickedAkc(event: Event) {
    let fileList: FileList | null = (event.target as HTMLInputElement).files;
    if ((fileList) && (fileList.length > 0)) {
      this.ofaform.controls['ofaCSV'].patchValue({image: fileList[0]});
      this.formProcessing = true;
      this.service.processAkcReport(fileList[0])
        .subscribe((res) => {
            this.formProcessing = false;
            this.responseObj = res;
            this.responseString = this.responseObj.responseString
          },
          (error) => {
            this.formProcessing = false;
            this.responseString = "An error occurred while processing this file";
          });
    }
  }

  onImagePickedWdc(event: Event) {
    let fileList: FileList | null = (event.target as HTMLInputElement).files;
    if ((fileList) && (fileList.length > 0)) {
      this.ofaform.controls['ofaCSV'].patchValue({image: fileList[0]});
      this.formProcessing = true;
      this.service.processWdcReport(fileList[0])
        .subscribe((res) => {
            this.formProcessing = false;
            this.responseObj = res;
            this.responseString = this.responseObj.responseString
          },
          (error) => {
            this.formProcessing = false;
            this.responseString = "An error occurred while processing this file";
          });
    }
  }
}
