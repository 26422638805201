import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { FormMetrics } from '../../models/forms.model';
import { DogService } from '../../services/dog.service';
import { Subject, takeUntil } from 'rxjs';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent implements OnInit, OnDestroy {
  formMetrics: FormMetrics  = {opens: []};
  private unsubscribe$ = new Subject<void>();
  userIsAdmin = false;

  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 2 },
          table: { cols: 1, rows: 4 },
        };
      }

      return {
        columns: 4,
        miniCard: { cols: 1, rows: 1 },
        chart: { cols: 2, rows: 2 },
        table: { cols: 4, rows: 4 },
      };
    })
  );

  constructor(private breakpointObserver: BreakpointObserver,
              private dogService: DogService,
              private tokenService: TokenStorageService,
              private ngZone: NgZone) {}

  ngOnInit(): void {
    this.dogService.getFormMetrics(31)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.formMetrics = res;
        });
      });
    this.userIsAdmin = this.tokenService.userHasRole('admin') ||
      this.tokenService.userHasRole('superAdmin');
  }

  OnInit() {

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
