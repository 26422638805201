<div class="content" style="background-color: white;">
  <div class="who_we_are" style="padding: 1em 0;">
    <div class="container-fluid">
      <div *ngIf="userIsAdmin" class="row">
        <div class="col-md-12">
          <div class="overlay" *ngIf="showFormsTableSpinner">
            <div class="spinner-wrapper">
              <app-admin-spinner></app-admin-spinner>
            </div>
          </div>
          <table datatable [dtOptions]="dtOptions" #dtTable
                 class="table table-hover table-bordered display pl-2 pr-2 w-100"
                 style="cursor: pointer;">

          </table>

        </div>
      </div>
      <div *ngIf="!userIsAdmin" class="row mx-auto">
        <div class="col-md-6">
          <h4>You must be a dog admin to view this table.</h4>
        </div>
      </div>
    </div>
  </div>
</div>
