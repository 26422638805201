import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dog, DogPedigree, Offspring, Offspring2 } from '../models/dog.model';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { faCircleDown } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbsService } from '../services/breadcrumbs.service';

@Component({
  selector: 'app-offspring',
  templateUrl: './offspring.component.html',
  styleUrls: ['./offspring.component.scss']
})
export class OffspringComponent implements OnInit, AfterViewInit {
  @Input() summary: boolean = false;

  @Input() offspring: Offspring2[] = [];

  @Input() dog: Dog | undefined;
  @Input() member: boolean = false;
  @Output() tabSelected = new EventEmitter();

  constructor(private router: Router,
              private breadcrumbs: BreadcrumbsService) {
  }

  ngOnInit(): void {
    const tabEl = document.querySelector('a[data-bs-target="#v-pills-offspring"]')
    tabEl?.addEventListener('shown.bs.tab', event => {
      this.tabSelected.emit('Offspring');
    });
  }
  getCoatColor(description: string | undefined) {
    if (description) {
      return description?.charAt(0).toUpperCase() + description?.slice(1)
    } else {
      return 'Unknown';
    }
  }

  getParentName(parent: Offspring2) {
    if (parent.name === 'Unknown') {
      return parent.name + ' - id: ' + parent.id;
    } else {
      return parent.name;
    }
  }
  gotoDog(dogId: number | undefined, dogName: string | undefined) {
    if (dogId && (dogId > 0) && dogName) {
      this.breadcrumbs.pushDog(dogId, dogName);
      this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
      this.router.navigateByUrl('/search');
    }
  }

  protected readonly faCircleDown = faCircleDown;

  ngAfterViewInit(): void {

  }

  getRegName(offspring: Offspring) {
    if (offspring.registered_name === null) {
      return 'Unknown Name';
    } else {
      return offspring.registered_name;
    }
  }
  getDOB(offspring: Offspring) {
    if ( (offspring.date_of_birth === null) || (offspring.date_of_birth === '1/1/1970')) {
      return 'Unknown Date of birth';
    } else {
      return offspring.date_of_birth;
    }
  }
}
