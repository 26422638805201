import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommitteeMembers, DogResponse, HealthCategoriesResponse } from '../models/dog.model';

const AUTH_API = environment.dbUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
/*
const httpFormOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};
*/

@Injectable({
  providedIn: 'root'
})
export class DogService {

  isLoggedIn: boolean = false;
  private dbAdmins$: BehaviorSubject<CommitteeMembers>;

  constructor(private http: HttpClient,
              private ngZone: NgZone) {
    this.dbAdmins$ = new BehaviorSubject<CommitteeMembers>({committeeMembers: []});
  }

  public getDbAdmins(): BehaviorSubject<CommitteeMembers> {
    return this.dbAdmins$;
  }
  public loadDbAdmins() {
    this.readDbAdmins();
  }
  getForm(formId: number): Observable<any>  {
    return this.http.get(AUTH_API + '/forms/' + formId + '?workflow=true', httpOptions);
  }
  postForm(formId: number, body: any): Observable<any> {
    return this.http.post(AUTH_API + '/forms/' + formId,  JSON.stringify(body), httpOptions);
  }
  postNewForm(body: any): Observable<any> {
    return this.http.post(AUTH_API + '/forms',  JSON.stringify(body), httpOptions);
  }
  getFormsTable(parameters: any): Observable<any> {
    return this.http.post(AUTH_API + '/forms/table', parameters, httpOptions);
  }
  getDogTable(parameters: any): Observable<any> {
    return this.http.post(AUTH_API + '/dogs/table', parameters, httpOptions);
  }
  getDogInfo(dogId: number): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/info', httpOptions);
  }

  getDogPedigree(dogId: number, levels: number): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/pedigree?levels=' + levels, httpOptions);
  }

  getDogAwards(dogId: number): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/titles', httpOptions);
  }
  getDogHealth(dogId: number, withNew: boolean): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/fullhealth?useNew=' + (withNew ? 'true' : 'false'), httpOptions);
  }
  getDogHealthString(dogId: number | undefined): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/health', httpOptions);
  }
  getDogTitles(dogId: number | undefined): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/titles', httpOptions);
  }
  getHealthCategories() : Observable<HealthCategoriesResponse> {
    return this.http.get(AUTH_API + '/health');
  }
  addHealthCategory(dogId: number, body: any): Observable<any> {
    return this.http.post(AUTH_API + '/dogs/' + dogId + '/healthConditions',  JSON.stringify(body), httpOptions);
  }

  addPendingHealthCategory(dogId: number, body: any): Observable<any> {
    return this.http.post(AUTH_API + '/dogs/' + dogId + '/pendingHealthConditions',  JSON.stringify(body), httpOptions);
  }

  getDogOffspring(dogId: number): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/offspring', httpOptions);
  }
  getDogOffspring2(dogId: number): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/offspring2', httpOptions);
  }

  getPendingChanges(dogId: number): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/change', httpOptions);
  }

  getHealthChanges(dogId: number, formId: number, pending: boolean): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/healthConditions?formId=' + formId + (pending ? '&pending=true' : ''), httpOptions);
  }

  postPendingChange(body: any): Observable<any> {
    return this.http.post(AUTH_API + '/dogs/change',  JSON.stringify(body), httpOptions);
  }
  approvePendingChange(dogId: number, changeId: number, body: any): Observable<any> {
    return this.http.put(AUTH_API + '/dogs/' + dogId + '/change/' + changeId,  JSON.stringify(body), httpOptions);
  }
  approvePendingHealthChange(dogId: number, changeId: number, body: any): Observable<any> {
    return this.http.put(AUTH_API + '/dogs/' + dogId + '/healthConditions/' + changeId,  JSON.stringify(body), httpOptions);
  }
  getDogSiblings(dogId: number): Observable<any> {
    return this.http.get(AUTH_API + '/dogs/' + dogId + '/siblings', httpOptions);
  }

  getFormMetrics(numDays: number): Observable<any> {
    return this.http.get(AUTH_API + '/forms/metrics?commId=71&days=' + numDays, httpOptions);
  }

  processOfaQuarterlyReport(reportFile: File): Observable<any> {
    const formData = new FormData();
    formData.append('report', reportFile);

    return this.http.post(AUTH_API+ '/dogs/ofareport/quarterly', formData);
  }

  processOfaSearchReport(reportFile: File): Observable<any> {
    const formData = new FormData();
    formData.append('report', reportFile);

    return this.http.post(AUTH_API+ '/dogs/ofareport/search', formData);
  }

  processAkcReport(reportFile: File): Observable<any> {
    const formData = new FormData();
    formData.append('report', reportFile);

    return this.http.post(AUTH_API+ '/dogs/akcreport', formData);
  }
  processWdcReport(reportFile: File): Observable<any> {
    const formData = new FormData();
    formData.append('report', reportFile);

    return this.http.post(AUTH_API+ '/dogs/ncareport', formData);
  }
  readDbAdmins() {
    this.http.get(AUTH_API + '/groups/750681/members', httpOptions)
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('committeeMembers')) {
            this.dbAdmins$.next(<CommitteeMembers>res);
          }
        });
      });
  }
}
