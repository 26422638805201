<div class="content text-center">
  <div class="who_we_are">
    <div class="container">
      <div class="grid_12">
        <header>
          <h3>Frequently Asked Questions</h3>
        </header>
        <h4 align="left">Browse by kennel</h4>
        <p align="left">If you would like to browse all dogs with a certain kennel name, type just the kennel name without any "s" or apostrophe "s" at the end. Some kennels have two word names that in some cases may have been condensed to get a name shortened. In those cases you may need to search both. For example – search "Little Bear" and "Littlebear."</p>

        <h4 align="left">Dog not found</h4>
        <p align="left">If your search does not show the dog you are looking for, search for a smaller fragment of the dog's registered name, leaving out all apostrophes, hyphens or other punctuation marks.</p>

        <p align="left">Dogs are entered according to their AKC registered names. If the kennel name, for example, is spelled differently on the AKC registration, it will appear that way in the search results. For example – Newton-Ark, Newtonark and Newton Ark. A good search to show all of these dogs would be to type "Newton" in the search field.</p>

        <h4 align="left">Database contents</h4>
        <p align="left">The NCA database contains over 30,000 Newfoundland dogs born from 1900 to the present that have either earned NCA, AKC titles OR had genetic health testing results listed in a publicly accessible database.</p>

        <h4 align="left">Finding a breeder</h4>
        <p align="left">The NCA database contains many years worth of data. Not all breeders included in the database have active breeding programs. Contact information for individual breeders is not provided via this database.</p>

        <div class="text-start">
          <h4>ALC: Ancestor Loss Coefficient</h4>
          <p>The Ancestor Loss is a good indication for the amount of linebreeding and inbreeding or the degree of kinship of a certain dog. The Ancestor Loss is calculated by comparing the number of ancestors that were possible in that pedigree with the actual number of different ancestors. It describes the degree to which recessive traits from ancestor generations are lost. Another way to think of it is as how many unique ancestors an animal has. If there are no duplicate ancestors, then no unique ancestors are “lost”, and the ALC = 100%. When there are duplicate ancestors, more and more unique ancestors are “lost”, and the ALC percentage goes down. A higher number is preferable.
            <br>
            ALC3 is the calculation for a 3-generation pedigree and ALC4 is the calculation for a 4-generation pedigree.</p>
        </div>

        <h2 align="left">Directory of Prefixes and Suffixes</h2>
        <p align="left">&nbsp;</p>
        <h4 align="left">Directory of Prefixes</h4>
        <table>
          <tr>
            <th>Abbreviation</th>
            <th>Title</th>
            <th>Organization</th>
          </tr>
          <tr class='d0'>
            <td>Ch</td>
            <td>American Champion</td>
            <td>AKC</td>
          </tr>
          <tr class='d1'>
            <td>Can Ch</td>
            <td>Canadian Champion</td>
            <td>CKC</td>
          </tr>
          <tr class='d0'>
            <td>MACH</td>
            <td>Master Agility Champion</td>
            <td>AKC</td>
          </tr>
          <tr class='d1'>
            <td>OTCH</td>
            <td>Obedience Trial Champion</td>
            <td>AKC</td>
          </tr>
          <tr class='d0'>
            <td>UN</td>
            <td>Utility Newfoundland (Canadian version of VN)</td>
            <td>CKC</td>
          </tr>
          <tr class='d1'>
            <td>VN</td>
            <td><a href="https://www.thenewfoundland.org/vn.php">Versatile  Newfoundland</a></td>
            <td>NCA</td>
          </tr>
          <tr class='d0'>
            <td>WA</td>
            <td><a href="https://www.thenewfoundland.org/wa.php">Working Achievement Award</a></td>
            <td>NCA</td>
          </tr>
        </table>

        <h4 align="left">&nbsp;</h4>
        <h4 align="left">Directory of Suffixes</h4>
        <table>
          <tr>
            <th>Abbreviation</th>
            <th>Title</th>
            <th>Organization</th>
          </tr>
          <tr class="d0">
            <td>BDD</td>
            <td>Beginner Draft Dog</td>
            <td>NCA</td>
          </tr>
          <tr class='d0'>
            <td>DD</td>
            <td>Draft Dog</td>
            <td>NCA</td>
          </tr>
          <tr class='d0'>
            <td>DDX</td>
            <td>Draft Dog Excellent</td>
            <td>NCA</td>
          </tr>
          <tr class='d1'>
            <td>TDD</td>
            <td>Team Draft Dog</td>
            <td>NCA</td>
          </tr>
          <tr class='d1'>
            <td>TDDX</td>
            <td>Team Draft Dog Excellent</td>
            <td>NCA</td>
          </tr>
          <tr class='d0'>
            <td>WD</td>
            <td>Water Dog (Junior)</td>
            <td>NCA</td>
          </tr>
          <tr class='d1'>
            <td>WRD</td>
            <td>Water Rescue Dog (Senior)</td>
            <td>NCA</td>
          </tr>
          <tr class='d0'>
            <td>WRDX</td>
            <td>Water Rescue Dog Excellent</td>
            <td>NCA</td>
          </tr>
          <tr class='d1'>
            <td>TOD</td>
            <td>Tested - open database listed</td>
            <td>NCA</td>
          </tr>
          <tr class='d0'>
            <td>TODD</td>
            <td>Tested – open database & DNA banked</td>
            <td>NCA</td>
          </tr>
          <tr class='d1'>
            <td>Can DD</td>
            <td>Canadian Draft Dog</td>
            <td>CKC</td>
          </tr>
          <tr class='d0'>
            <td>Can DDX</td>
            <td>Canadian Draft Dog Excellent</td>
            <td>CKC</td>
          </tr>
          <tr class='d1'>
            <td>Can TDD</td>
            <td>Canadian Team Draft Dog</td>
            <td>CKC</td>
          </tr>
          <tr class='d0'>
            <td>Can WRD</td>
            <td>Canadian Water Rescue Dog (Junior)</td>
            <td>CKC</td>
          </tr>
          <tr class='d1'>
            <td>Can WRDX</td>
            <td>Canadian Water Rescue Dog Excellent (Senior)</td>
            <td>CKC</td>
          </tr>
          <tr class='d0'>
            <td>ROM</td>
            <td><a href="https://www.thenewfoundland.org/rom.php">Register of Merit</a></td>
            <td>NCA</td>
          </tr>
        </table>
        <h4 align="left">&nbsp;</h4>
        <h4 align="left">Looking for a full listing of prefixes and suffixes?</h4>
        <p align="left">Visit the <a href="http://www.akc.org/events/titles.cfm?page=2">American Kennel Club Title Directory</a> for a full list available from the AKC.<br /></p>

        <h2 align="left">Abbreviations</h2>
        <h4 align="left">Sample of Health Abbreviations Commonly Used</h4>
        <p align="left">&nbsp;</p>
        <table>
          <tr>
            <th>Abbreviation</th>
            <th>Description</th>
          </tr>
          <tr class='d0'>
            <td>HE</td>
            <td>OFA Excellent Hips</td>
          </tr>
          <tr class='d1'>
            <td>HG</td>
            <td>OFA Good Hips</td>
          </tr>
          <tr class='d0'>
            <td>HF</td>
            <td>OFA Fair Hips</td>
          </tr>
          <tr class='d1'>
            <td>HN</td>
            <td>Hips normal (older certs did not include rating)</td>
          </tr>
          <tr class='d0'>
            <td>PH</td>
            <td>Penn Hip evaluation</td>
          </tr>
          <tr class='d1'>
            <td>HOVC</td>
            <td>OVC hips Clear</td>
          </tr>
          <tr class='d0'>
            <td>HELOVC</td>
            <td>OVC Hips & ELbows Clear</td>
          </tr>
          <tr class='d1'>
            <td>CA</td>
            <td>OFA Heart cleared by cardiologist</td>
          </tr>
          <tr class='d0'>
            <td>CAE</td>
            <td>OFA Heart cleared - echodoppler</td>
          </tr>
          <tr class='d1'>
            <td>Hr</td>
            <td>Heart cleared by cardiologist (NCA listing only not OFA)</td>
          </tr>
          <tr class='d0'>
            <td>HrS</td>
            <td>Heart cleared by specialist</td>
          </tr>
          <tr class='d1'>
            <td>HrP</td>
            <td>OFA Heart cleared by practitioner</td>
          </tr>
          <tr class='d0'>
            <td>EL</td>
            <td>OFA elbows clear</td>
          </tr>
          <tr class='d1'>
            <td>PA</td>
            <td>OFA Patellas clear</td>
          </tr>
          <tr class='d0'>
            <td>SH</td>
            <td>OFA Shoulders clear</td>
          </tr>
          <tr class='d1'>
            <td>TH</td>
            <td>OFA Thyroid clear</td>
          </tr>
          <tr class='d0'>
            <td>C</td>
            <td>CERF clear for eyes (current year only)</td>
          </tr>
          <tr class='d1'>
            <td>CYN</td>
            <td>Cystinuria normal</td>
          </tr>
          <tr class='d0'>
            <td>CYC</td>
            <td>Cystinuria carrier</td>
          </tr>
          <tr class='d1'>
            <td>CYA</td>
            <td>Cystinuria affected</td>
          </tr>
          <tr class='d0'>
            <td>D</td>
            <td>DNA donated for canine research</td>
          </tr>
        </table>
        <h4 align="left">&nbsp;</h4>
        <h4 align="left">Color Abbreviations</h4>
        <p align="left">&nbsp;</p>
        <table>
          <tr>
            <th>Abbreviation</th>
            <th>Color</th>
          </tr>
          <tr class='d0'>
            <td>B</td>
            <td>Black</td>
          </tr>
          <tr class='d1'>
            <td>B/W</td>
            <td>black with white markings</td>
          </tr>
          <tr class='d0'>
            <td>B/Br</td>
            <td>black with brown markings</td>
          </tr>
          <tr class='d1'>
            <td>B/Gr</td>
            <td>black with grey markings</td>
          </tr>
          <tr class='d0'>
            <td>B/T</td>
            <td>black with tan markings</td>
          </tr>
          <tr class='d1'>
            <td>W</td>
            <td>white</td>
          </tr>
          <tr class='d0'>
            <td>W/B</td>
            <td>white with black markings</td>
          </tr>
          <tr class='d1'>
            <td>W/Br</td>
            <td>white with brown markings</td>
          </tr>
          <tr class='d0'>
            <td>W/Gr</td>
            <td>white with grey markings</td>
          </tr>
          <tr class='d1'>
            <td>W/T</td>
            <td>white with tan markings</td>
          </tr>
          <tr class='d0'>
            <td>Gr</td>
            <td>grey</td>
          </tr>
          <tr class='d1'>
            <td>Gr/B</td>
            <td>grey with black markings</td>
          </tr>
          <tr class='d0'>
            <td>Gr/W</td>
            <td>grey with white markings</td>
          </tr>
          <tr class='d1'>
            <td>Gr/Br</td>
            <td>grey with brown markings</td>
          </tr>
          <tr class='d0'>
            <td>Br</td>
            <td>brown</td>
          </tr>
          <tr class='d1'>
            <td>Br/B</td>
            <td>brown with black markings</td>
          </tr>
          <tr class='d0'>
            <td>Br/W</td>
            <td>brown with white markings</td>
          </tr>
          <tr class='d1'>
            <td>Br/Gr</td>
            <td>brown with grey markings</td>
          </tr>
          <tr class='d0'>
            <td>T</td>
            <td>tan</td>
          </tr>
          <tr class='d1'>
            <td>Be</td>
            <td>beige</td>
          </tr>
          <tr class='d1'>
            <td>Bu</td>
            <td>buff</td>
          </tr>
          <tr class='d0'>
            <td>Bl</td>
            <td>blond</td>
          </tr>
          <tr class='d1'>
            <td>Cr</td>
            <td>cream</td>
          </tr>
          <tr class='d0'>
            <td>H</td>
            <td>honey</td>
          </tr>
          <tr class='d1'>
            <td>R</td>
            <td>roan</td>
          </tr>
          <tr class='d0'>
            <td>Y</td>
            <td>yellow</td>
          </tr>
          <tr class='d1'>
            <td>D  (Dilute)</td>
            <td>pale shade</td>
          </tr>
          <tr class='d0'>
            <td>Bl</td>
            <td>blue</td>
          </tr>
        </table>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <app-why-choose-us></app-why-choose-us>
  <app-more-ways-to-connect></app-more-ways-to-connect>
</div>
