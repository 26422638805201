<div class="why_choose_us">
  <div class="container">
    <div class="row">
      <header>
        <h3>Additional Resources</h3>
      </header>
    </div>
    <div class="row">
      <div class="col-md-4 grid_4">
        <div class="mx-auto">
          <span>01</span>
          <p><a href="https://www.newftide.org">Subscribe to <em>Newf Tide</em></a></p>
        </div>
      </div>
      <div class="col-md-4 grid_4">
        <div class="mx-auto">
          <span>02</span>
          <p><a href="https://lp.constantcontactpages.com/su/jDhV7NM/enotes">Subscribe to e-Notes</a></p>

        </div>
      </div>
      <div class="col-md-4 grid_4">
        <div class="mx-auto">
          <span>03</span>
          <p><a href="mailto:info@ncadogs.org">Questions about Newfs</a></p>

        </div>
      </div>
    </div>
  </div>
</div>
