<h2 mat-dialog-title>{{ prompt }}</h2>
<form [formGroup]="healthEditForm" id="profile-edit-form" class="input-group" role="form">

<mat-dialog-content>
  <select matNativeControl formControlName="healthCategory" (change)="updateHealthOptions()">
    <option value="">Select</option>
    <option *ngFor="let category of healthCategoryNames; let i = index" [value]="category">{{category}}</option>
  </select>
  <select matNativeControl #queryNameInput formControlName="healthCategoryOption">
    <option value="">Select</option>
    <option *ngFor="let anOption of healthCategoryOptionNames; let i = index" [value]="anOption">{{anOption}}</option>
  </select>
</mat-dialog-content>
</form>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" (click)="onSubmit(queryNameInput.value)">Save</button>
</mat-dialog-actions>
