import { Injectable } from '@angular/core';
import { DogBreadcrumb } from '../models/dog.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  private dogTracks$: BehaviorSubject<DogBreadcrumb[]>;
  private dogTracks: DogBreadcrumb[] = [];

  constructor() {
    this.dogTracks$ = new BehaviorSubject<DogBreadcrumb[]>([]);
  }

  public getCurrentBreadcrumbNotification(): BehaviorSubject<DogBreadcrumb[]> {
    return this.dogTracks$;
  }
  pushDog(dogId: number, dogName: string) {
    this.dogTracks.push({dogId: dogId, dogFullName: dogName});
    this.dogTracks$.next(this.dogTracks);
  }

  getCurrentDog(): DogBreadcrumb {
    if (this.dogTracks.length === 0) {
      return {dogId: 0, dogFullName: ''};
    } else {
      return this.dogTracks[this.dogTracks.length - 1];
    }
  }

  getCurrentDogArray(): DogBreadcrumb[] {
    return this.dogTracks;
  }

  updateDogInfo(dogId: number | undefined, dogName: string | undefined) {
    if (dogName) {
      this.dogTracks.forEach(adog => {
        if (adog.dogId === dogId) {
          adog.dogFullName = dogName;
        }
      });
      this.dogTracks$.next(this.dogTracks);
    }
  }
  popDog() {
    if (this.dogTracks.length > 0) {
      this.dogTracks.pop();
      this.dogTracks$.next(this.dogTracks);
    }
  }
  popToDog(dogId: number) {
    if (this.dogTracks.length > 0) {
      var gotToDog = false;
      do {
        if (this.dogTracks[this.dogTracks.length - 1].dogId === dogId) {
          gotToDog = true;
        } else {
          this.dogTracks.pop();
        }
      } while ((this.dogTracks.length > 0) && !gotToDog);
    }
    this.dogTracks$.next(this.dogTracks);
  }

  clearDogTrack() {
    this.dogTracks = [];
    this.dogTracks$.next(this.dogTracks);
  }
}
