<div class="container-fluid">
  <div class="row p-3 mx-auto">
    <div *ngIf="isDogAdmin" class="col-md-4 border-end border-dark border-2">
      <h3 class="text-center w-100">OFA Import</h3>
      <h5>Steps to import new OFA data</h5>
      <p>You must pull down both a Quarterly Report as well as an Advanced Search report in order to get all of the data required</p>
      <ul>
        <li>Click here to get the latest quarterly report: <a href="https://ofa.org/chic-programs/browse-by-breed/?breed=NF" target="_blank">OFA Quarterly Report Form</a></li>
        <li>Make sure that the "Breed Report" option is checked</li>
        <li>Select the quarter containing the most recent data you need</li>
        <li>Hit the download button</li>
        <li>Click here to get the latest Advanced Search report: <a href="https://ofa.org/advanced-search/" target="_blank">Advanced Search Form</a> </li>
        <li>Enter "newf" in the "Breed Name" field and select the "NEWFOUNDLAND" when it shows</li>
        <li>Click on "Prior Month" in the "Reporting Year" box (or choose a year for the data you want to import)</li>
        <li>Click on the "Begin Search" button</li>
        <li>When the search results come back, click on the "Multi-line csv file" download button</li>
        <li>Use the two files you downloaded to import the latest OFA data</li>
      </ul>
      <form [formGroup]="ofaform" id="ofa-form" class="input-group" role="form">
        <label for="fpick1">Select a Quarterly export file:</label>
        <input type="file" #filePicker id="fpick1" class="mb-3" (change)="onImagePickedOfa($event)" style="text-align-last: center;">
        <br>
        <label for="fpick2">Select a Search export file:</label>
        <input type="file" #filePicker id="fpick2" (change)="onImagePickedOfaSearch($event)" style="text-align-last: center;">
      </form>
    </div>
    <div *ngIf="isDogAdmin" class="col-md-4 border-end border-dark border-2">
      <h3 class="text-center w-100">AKC Import</h3>
      <form [formGroup]="akcform" id="akc-form" class="input-group" role="form">
        <input type="file" #filePicker (change)="onImagePickedAkc($event)" style="text-align-last: center;">
      </form>
    </div>
    <div *ngIf="isWdcAdmin" class="col-md-4">
      <h3 class="text-center w-100">NCA WDC Import</h3>
      <form [formGroup]="wdcform" id="wdc-form" class="input-group" role="form">
        <input type="file" #filePicker (change)="onImagePickedWdc($event)" style="text-align-last: center;">
      </form>
    </div>
  </div>
  <div class="row mt-3 p-3">
    <div *ngIf="formProcessing">
      <div class="col-md-6">
        <div class="spinner-wrapper">
          <app-admin-spinner [message]="loadingString"></app-admin-spinner>
        </div>
      </div>
    </div>
    <div *ngIf="!formProcessing">
      <div class="col-md-12">
        <p id="response" [innerText]="responseString"></p>
      </div>
    </div>
  </div>
</div>
