<div class="content text-center">
  <div>
    <div class="container-fluid table-container">
      <div class="grid_12">
        <header>
          <h4>Registered Name: {{ dog?.registered_name }}</h4>
        </header>
        </div>
      <div class="grid_12 table-responsive">
        <div *ngIf="member">
          <table class="table table-bordered table-striped table-hover mt-4 text-start">
            <thead>
            <tr>
              <th>Name</th>
              <th>Health</th>
              <th>Date of Birth</th>
              <th>Date of Death</th>
              <th>Gender</th>
              <th>Coat Color</th>
            </tr>
            </thead>
            <tbody>
            <tr class="heading">
              <td colspan="6" style="font-weight: bold;color: white;background-color: rgba(38, 100, 100, 0.5);">Full Siblings</td>
            </tr>
            <tr *ngFor="let sibs of siblings.fullSiblings" (click)="gotoDog(sibs.dog_id, sibs.registered_name)" class="sibling-link">
              <td class="sibling">{{ getRegName(sibs) }}</td>
              <td>{{ sibs.health }}</td>
              <td>{{ getDOB(sibs) }}</td>
              <td>{{ sibs.date_of_death }}</td>
              <td>{{ sibs.sex }}</td>
              <td>{{ getCoatColor(sibs.coat_color) }}</td>
            </tr>
            <tr *ngIf="siblings.fullSiblings.length === 0">
              <td colspan="3">No full sibling data found</td>
            </tr>
            <tr class="heading">
              <td colspan="6" style="font-weight: bold;color: white;background-color: rgba(38, 100, 100, 0.5);">Half Siblings (Sire)</td>
            </tr>
            <tr *ngFor="let sibs of siblings.sireHalfSiblings" (click)="gotoDog(sibs.dog_id, sibs.registered_name)" class="sibling-link">
              <td class="sibling">{{ getRegName(sibs) }}</td>
              <td>{{ sibs.health }}</td>
              <td>{{ getDOB(sibs) }}</td>
              <td>{{ sibs.date_of_death }}</td>
              <td>{{ sibs.sex }}</td>
              <td>{{ getCoatColor(sibs.coat_color) }}</td>
            </tr><tr *ngIf="siblings.sireHalfSiblings.length === 0">
              <td colspan="3">No Sire half sibling data found</td>
            </tr>
            <tr class="heading">
              <td colspan="6" style="font-weight: bold;color: white;background-color: rgba(38, 100, 100, 0.5);">Half Siblings (Dam)</td>
            </tr>
            <tr *ngFor="let sibs of siblings.damHalfSiblings" (click)="gotoDog(sibs.dog_id, sibs.registered_name)" class="sibling-link">
              <td class="sibling">{{ getRegName(sibs) }}</td>
              <td>{{ sibs.health }}</td>
              <td>{{ getDOB(sibs) }}</td>
              <td>{{ sibs.date_of_death }}</td>
              <td>{{ sibs.sex }}</td>
              <td>{{ getCoatColor(sibs.coat_color) }}</td>
            </tr>
            <tr *ngIf="siblings.damHalfSiblings.length === 0">
              <td colspan="3">No Dam half sibling data found</td>
            </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <div *ngIf="!summary">
            <app-data-display></app-data-display>
          </div>
        </div>
        <div *ngIf="!member" class="row">
          <div class="col-md-12">
            <h5 style="color: #900909;">This feature is available to NCA Members, please use the red LOGIN button above to enter your Member Portal email and password. <br>If you would like to access this feature and more, <a href="https://members.ncanewfs.org/" target="_blank">learn about joining the NCA</a></h5>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
