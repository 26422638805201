<div class="row">
  <div class="col-md-12">
    <p><span>History:&nbsp;</span>
      <ng-container *ngFor="let aDog of trackedDogs">
        <span class="history" (click)="gotoDog(aDog)">{{ aDog.dogFullName }}</span>
        <span class="next" *ngIf="!isLastDog(aDog)">&nbsp;>>&nbsp;</span>
      </ng-container>
    </p>
  </div>
</div>
