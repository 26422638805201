import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dog, Offspring, Sibling, SiblingResponse } from '../models/dog.model';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '../services/breadcrumbs.service';

@Component({
  selector: 'app-siblings',
  templateUrl: './siblings.component.html',
  styleUrls: ['./siblings.component.scss']
})
export class SiblingsComponent implements OnInit {
  @Input() summary: boolean = false;

  @Input() siblings: SiblingResponse = {fullSiblings: [], sireHalfSiblings: [], damHalfSiblings: []};

  @Input() dog: Dog | undefined;
  @Input() member: boolean = false;
  @Output() tabSelected = new EventEmitter();

  constructor(private router: Router,
              private breadcrumbs: BreadcrumbsService) {
  }
  getCoatColor(description: string | undefined) {
    if (description) {
      return description?.charAt(0).toUpperCase() + description?.slice(1)
    } else {
      return 'Unknown';
    }
  }

  gotoDog(dogId: number | undefined, dogName: string | undefined) {
    if (dogId && (dogId > 0) && dogName) {
      this.breadcrumbs.pushDog(dogId, dogName);
      this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
      this.router.navigateByUrl('/search');
    }
  }

  ngOnInit(): void {
    const tabEl = document.querySelector('a[data-bs-target="#v-pills-siblings"]')
    tabEl?.addEventListener('shown.bs.tab', event => {
      this.tabSelected.emit('Siblings');
    });
  }

  getRegName(sibling: Sibling) {
    if (sibling.registered_name === null) {
      return 'Unknown Name';
    } else {
      return sibling.registered_name;
    }
  }
  getDOB(sibling: Sibling) {
    if ( (sibling.date_of_birth === null) || (sibling.date_of_birth === '1/1/1970')) {
      return 'Unknown Date of birth';
    } else {
      return sibling.date_of_birth;
    }
  }
}
