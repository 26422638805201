<!--dash.component.html-->
<div class="grid-container">
  <h1 class="mat-h1">Dashboard</h1>
  <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="200px">
    <!--Mini Cards-->
    <mat-grid-tile [colspan]="( cardLayout | async )?.chart?.cols" [rowspan]="( cardLayout | async )?.chart?.rows">
      <app-card>
        <app-sub-by-date [metrics]="formMetrics"></app-sub-by-date>
      </app-card>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols" [rowspan]="( cardLayout | async )?.table?.rows">
      <app-card title="Correction Forms"><div><app-forms-table></app-forms-table></div></app-card>
    </mat-grid-tile>

    <!--
    <mat-grid-tile *ngFor="let i of [1, 2, 3]" [colspan]="( cardLayout | async )?.miniCard?.cols"
                   [rowspan]="( cardLayout | async )?.miniCard?.rows">
      <app-card title="Card {{i}}"><div>Mini Card Content Here</div></app-card>
    </mat-grid-tile>
    <mat-grid-tile *ngFor="let i of [5, 6, 7, 8]" [colspan]="( cardLayout | async )?.chart?.cols"
                   [rowspan]="( cardLayout | async )?.chart?.rows">
      <app-card title="Card {{i}}"><div>Chart Content Here</div></app-card>
    </mat-grid-tile>
    -->
  </mat-grid-list>
</div>
