<div class="content text-center">
  <div class="who_we_are">
    <div class="container">
      <div class="grid_12">
        <header>
          <h3>Search Results</h3>
        </header>
        <table width="90%" border="0">
          <tbody>
          <tr>
            <td width="16%">Name</td>
            <td width="16%">Date of Birth</td>
            <td width="16%">Date of Death</td>
            <td width="16%">Gender</td>
            <td width="16%">Color</td>
            <td width="16%">&nbsp;</td>
          </tr>
          <tr>
            <td>dog 1</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td></td>
            <td>Select</td>
          </tr>
          <tr>
            <td>dog 2</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>Select</td>
          </tr>
          <tr>
            <td>dog 3</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td></td>
            <td>&nbsp;</td>
            <td>Select</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2" align="center"><button type="button"><a href="publication.html">Publication Report</a></button></td>
            <td colspan="2" align="center"><button type="button"><a href="submitphoto.html">Submit Photo</a></button></td>
            <td colspan="2" align="center"><button type="button"><a href="correction.html">Submit A Correction</a></button></td>
          </tr>
          </tbody>
        </table>

      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
