<div class="content text-center">
  <div class="who_we_are">
    <div class="container">
      <div class="grid_12">
        <header>
          <h3>Contact Us</h3>
        </header>
        <p align="left"><a href="committees.html">Committee Directory</a></p>
        <p align="left"><a href="regionalclubs.html">Regional Club Directory</a></p>
        <p align="left"><a href="https://www.ncarescue.org/contact.html">Rescue Contact List</a></p>
        <p align="left"><a href="mailto:breederreferral@ncadogs.org">Breeder Referral</a> or text "PUPPY" to 360-564-5750 for information and resources to find a reputable Newfoundland breeder. </p>

        <p align="left"><a href="https://www.newfoundlandpuppy.org/ambassadors.html">Newfoundland Ambassadors</a></p>
        <p align="left"><a href="https://www.ncadogs.org/jmentors.html">Judge's Education - Breed Mentors</a></p>
        <p align="left"><a href="https://www.newfoundlandbreeder.org/bmentors.html">Breeder Support Mentors</a></p>
        <p align="left"><a href="mailto:membership@ncadogs.org>">NCA Membership Questions</a> or visit our <a href="https://members.ncanewfs.org">Membership Portal</a></p>
        <p align="left"><a href="mailto:correspondence@ncadogs.org">Corresponding Secretary</a></p>
        <p align="left"><a href="board.php">Board of Directors</a></p>
        <p align="left"><a href="mailto:webmaster@ncadogs.org">Website Comments</a></p>
        <p align="left"><a href="mailto:editor@ncadogs.org"><em>Newf Tide</em> Editor</a></p>

      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <app-why-choose-us></app-why-choose-us>
  <app-more-ways-to-connect></app-more-ways-to-connect>
</div>
