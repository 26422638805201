import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { faCircleDown } from '@fortawesome/free-solid-svg-icons';
import {
  Award,
  Dog,
  DogPedigree,
  Health,
  HealthResponse,
  Offspring,
  Offspring2,
  Sibling,
  SiblingResponse
} from '../models/dog.model';
import domToImage from 'dom-to-image';
import jsPDF, { RGBAData } from 'jspdf';
import { DateTime } from 'luxon';
import { EditModes } from '../models/forms.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})

export class SummaryComponent {

  @Input() siblings: SiblingResponse = {fullSiblings: [], sireHalfSiblings: [], damHalfSiblings: []};
  @Input() offspring: Offspring2[] = [];
  @Input() healths: HealthResponse = {health: [{}], conditions: [{}], conditionsCount: 0};
  @Input() awards: Award[] = [];
  @Input() pedigree: DogPedigree = {acl3: 0, acl4: 0, pedigree: {}};
  @Input() hasHealthCondition: boolean = false;
  @Input() hasCauseOfDeath: boolean = false;
  @Input() providedDOB: string | undefined;
  @Input() providedDOD: string | undefined;
  @Input() formId = -1;
  @Input() dog: Dog = { registry_info: [] };
  @Input() member: boolean = false;
  @Input() editMode = EditModes.notEditable; // 0 not editable, 1 = editable, 2 = waiting for approval, 3 = done
  @ViewChild('summaryExportView', {static: false}) summaryExportView: ElementRef | undefined;

  protected readonly faCircleDown = faCircleDown;
  shouldShow = false;

  exportSummary() {
    const pev = document.getElementById('summary-section');
    if (pev != null) {
      pev.classList.remove('d-none');
      const pedPart = document.getElementById('pedPart');
      if (pedPart != null) {
        pedPart.classList.add('d-none');
      }
      const width = this.summaryExportView?.nativeElement.clientWidth;
      const height = this.summaryExportView?.nativeElement.clientHeight + 40;
      let orientation: "p" | "portrait" | "l" | "landscape";
      let imageUnit: "pt" | "px" | "in" | "mm" | "cm" | "ex" | "em" | "pc" = 'pt';
      if (width > height) {
        orientation = 'l';
      } else {
        orientation = 'p';
      }
      domToImage
        .toPng(this.summaryExportView?.nativeElement, {
          width: width,
          height: height
        })
        .then((result: string | HTMLImageElement | HTMLCanvasElement | Uint8Array | RGBAData) => {
          let jsPdfOptions = {
            orientation: orientation,
            unit: imageUnit,
            format: [width + 50, height + 220]
          };
          const pdf = new jsPDF(orientation, imageUnit, [width + 50, height + 220], false);
          pdf.addImage(result, 'PNG', 25, 25, width, height);
          pdf.save('NCADogSummaryReport-' + DateTime.now().toFormat('MM-dd-yyyy')+ '.pdf');
          const pedPart = document.getElementById('pedPart');
          if (pedPart != null) {
            pedPart.classList.remove('d-none');
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  protected readonly EditModes = EditModes;
}
