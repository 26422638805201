import { AdminComponent } from './admin.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashComponent } from './dash/dash.component';
import { DogsComponent } from './dogs/dogs.component';
import { ReportsComponent } from './reports/reports.component';
import { SystemComponent } from './system/system.component';
import { FormDetailsComponent } from './form-details/form-details.component';

const  routes: Routes  = [
  {
    path:  'admin',
    component:  AdminComponent,
    children: [
      {
        path: '',
        component: DashComponent
      },
      {
        path: 'dashboard',
        component: DashComponent
      },
      {
        path: 'dogs',
        component: DogsComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'system',
        component: SystemComponent
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export  class  AdminRoutingModule { }
