<div id="app">
  <div class="footer-wrapper">
    <app-header></app-header>
    <div class="container-fluid">
      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="isLoginFailed"
      >
        Login failed: {{ errorMessage }}
      </div>

      <router-outlet></router-outlet>
    </div>
    <div class="push"></div>
  </div>
  <app-footer></app-footer>

</div>
