<div class="content text-center">
  <div>
    <div class="container-fluid table-container">
      <div class="grid_12">
        <header>
          <h4>Registered Name: {{ dog?.registered_name }}</h4>
        </header>
      </div>
      <div class="grid_12 table-responsive">
        <div *ngIf="member">
          <table class="table table-bordered mt-4">
            <tbody>
            <ng-container *ngFor="let offs of offspring">
              <tr class="sibling-link parent-header accordion-toggle"  data-bs-toggle="collapse" [attr.data-bs-target]="'#demo' + offs.id">
                <td colspan="5" style="vertical-align: middle;">Bred to: <strong>{{ getParentName(offs) }}</strong> - {{ offs.offspring?.length }} offspring</td>
                <td style="width: 40px;"><button class="btn btn-default btn-xs" style="background-color: transparent;">
                  <fa-icon [icon]="faCircleDown"></fa-icon>
                </button></td>
              </tr>
              <tr>
                <div class="accordion-body collapse show"  [id]="'demo' + offs.id">
                  <table class="table table-hover table-bordered">
                    <thead>
                    <tr style="text-align: start;">
                      <th>Name</th>
                      <th>Health</th>
                      <th>Date of Birth</th>
                      <th>Date of Death</th>
                      <th>Gender</th>
                      <th>Coat Color</th>
                    </tr>
                    </thead>
                    <tr *ngFor="let anOff of offs.offspring"  (click)="gotoDog(anOff.dog_id, anOff.registered_name)" class="sibling-link">
                      <td class="child-dog">{{ getRegName(anOff) }}</td>
                      <td>{{ anOff.health }}</td>
                      <td>{{ getDOB(anOff) }}</td>
                      <td>{{ anOff.date_of_death }}</td>
                      <td>{{ anOff.sex }}</td>
                      <td>{{ getCoatColor(anOff.coat_color) }}</td>
                    </tr>
                  </table>
                </div>
              </tr>

            </ng-container>
            <tr *ngIf="offspring.length === 0">
              <td colspan="3">No offspring data found</td>
            </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <div  *ngIf="!summary">
            <app-data-display></app-data-display>
          </div>
        </div>
        <div *ngIf="!member" class="row">
          <div class="col-md-12">
            <h5 style="color: #900909;">This feature is available to NCA Members, please use the red LOGIN button above to enter your Member Portal email and password. <br>If you would like to access this feature and more, <a href="https://members.ncanewfs.org/" target="_blank">learn about joining the NCA</a></h5>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
