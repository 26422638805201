import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DogService } from '../../services/dog.service';
import { DogTableResponse, FormTableResponse } from '../../models/dog.model';
import { DateTime } from 'luxon';
import { DataTableDirective } from 'angular-datatables';
import { PedigreeComponent } from '../../pedigree/pedigree.component';
import { PopperContent } from 'ngx-popper/src/popper-content';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-forms-table',
  templateUrl: './forms-table.component.html',
  styleUrls: ['./forms-table.component.scss']
})
export class FormsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, {static : false}) dtElement: DataTableDirective | undefined;
  dtOptions: ADTSettings = {};

  showFormsTableSpinner = false;
  private unsubscribe$ = new Subject<void>();
  forms: FormTableResponse[] = [];
  totalFormsFound = 0;
  noData = true;
  userIsAdmin = false;

   constructor(private dogService: DogService,
               private modalService: ModalService,
               private tokenService: TokenStorageService,
               private router: Router,
               private ngZone: NgZone) {
  }
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.userIsAdmin = this.tokenService.userHasRole('admin') ||
      this.tokenService.userHasRole('superAdmin');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      searching: false,
      lengthChange: true,
      paging: true,
      info: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.ngZone.run(() => {
          this.showFormsTableSpinner = true;
          const userData = { committeeId: 71};
          this.dogService.getFormsTable(Object.assign(dataTablesParameters, userData))
            .subscribe(
              resp => {
                this.showFormsTableSpinner = false;
                if (resp.hasOwnProperty('formsList')) {
                  this.forms = resp.formsList;
                  this.totalFormsFound = resp.recordsFiltered;
                  this.noData = this.forms.length <= 0;
                } else {
                  resp['data'] = [];
                  resp['recordsTotal'] = 0;
                  resp['recordsFiltered'] = 0;
                }

                callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.formsList
                });
              },
              error => {
                this.showFormsTableSpinner = false;
                this.modalService.open('modal-search-error');
              });
        });
      },
      order: [[1, 'desc']],
      columns: [
        {
          title: 'ID',
          data: 'id'
        }, {
          title: 'Submit Date',
          data: 'submitDate'
        }, {
          title: 'State',
          data: 'toState'
        }, {
          title: 'Assigned To',
          data: 'assignedTo'
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.someClickHandler(data);
        });
        let rowData = data as FormTableResponse;
        let datenumeric: any;
        datenumeric = rowData.submitDate;
        const dateDate = DateTime.fromISO(datenumeric);
        $('td:eq(1)', row).text(dateDate.toLocaleString(DateTime.DATETIME_FULL));
        switch (rowData.toState) {
          case 'submitted':
            $('td:eq(2)', row).text('Submitted');
            break;
          case 'assigned':
            $('td:eq(2)', row).text('Assigned');
            break;
          case 'pendingApproval':
            $('td:eq(2)', row).text('Pending Approval');
            break;
          case 'resolvedDone':
            $('td:eq(2)', row).text('Approved');
            break;
          case 'resolvedRejected':
            $('td:eq(2)', row).text('Rejected');
            break;
        }
        return row;
      }
    };
  }

  someClickHandler(info: any): void {
    /*
    this.selectedDonor = {identity: info.identity};
    const dState: DonorState = {donor: this.selectedDonor, enhanced: info};
    this.store.dispatch(new donorActions.SetSelectedDonorAction(dState));
    this.router.navigateByUrl('/app/donor-details');
    */
    const formId = info.id;
    setTimeout(() => {
      // @ts-ignore
      window.scrollTo({left: 0, top: 0, behavior: "instant"});
    }, 200);
    this.router.navigate(['/', 'admin', 'form-details', formId]);
  }

}
