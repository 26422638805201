<div
  fileDrop
  [accept]="extensions"
  (fileOver)="onFileOver($event)"
  (fileDrop)="onFileChange($event)"
  [ngClass]="class"
  [ngClass]="{'img-ul-file-is-over': fileOver}"
  [ngStyle]="style?.layout || null"
>
  <div class="img-ul-file-upload img-ul-hr-inline-group">
    <div *ngIf="fileCounter != max">
      <label
        class="img-ul img-ul-upload img-ul-button"
        [ngStyle]="style?.selectButton || null"
        [ngClass]="{'img-ul-disabled': disabled}"
      >
        <span [innerText]="buttonCaption"></span>
        <input
          type="file"
          [disabled]="disabled"
          [accept]="extensions"
          multiple (change)="onFileChange(input.files)"
          #input>
      </label>
      <div class="img-ul-drag-box-msg" [innerText]="dropBoxMessage"></div>
    </div>
    <button *ngIf="fileCounter > 0"
            [disabled]="disabled"
            class="img-ul-clear img-ul-button"
            (click)="deleteAll()"
            [ngStyle]="style?.clearButton || null"
            [innerText]="clearButtonCaption">
    </button>
  </div>

  <p class="img-ul-file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage"></p>

  <div
    *ngIf="preview"
    class="img-ul-container img-ul-hr-inline-group"
    [ngStyle]="style?.previewPanel || null"
  >
    <div *ngFor="let file of files">
      <div *ngIf="!file.isPdf && !file.isTiff"
           class="img-ul-image"
           matTooltip="Click on the image to view it full-size."
           (click)="previewFileClicked(file)"
           [ngStyle]="{'background-image': 'url('+ file.src +')'}">
        <div *ngIf="file.pending" class="img-ul-loading-overlay">
          <div class="img-ul-spinning-circle"></div>
        </div>
      </div>
      <div *ngIf="!file.isPdf && file.isTiff"
           class="img-ul-image"
           matTooltip="Click on the image to view it full-size."
           (click)="previewFileClicked(file)"
           [ngStyle]="{'background-image': 'url(/assets/no_preview.png)'}">
        <div *ngIf="file.pending" class="img-ul-loading-overlay">
          <div class="img-ul-spinning-circle"></div>
        </div>
        <p>TIFF file uploaded successfully</p>
      </div>
      <div *ngIf="file.isPdf" class="img-ul-image">
        <pdf-viewer
          class="img-ul-pdf-image"
          [src]="file.pdfSrc"
          [original-size]="false"
          (click)="previewPDFFileClicked()">
        </pdf-viewer>
        <div *ngIf="file.pending" class="img-ul-loading-overlay">
          <div class="img-ul-spinning-circle"></div>
        </div>
      </div>
    </div>
  </div>
</div>
