import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { PedigreeComponent } from '../pedigree/pedigree.component';
import { PopperContent } from 'ngx-popper/src/popper-content';
import { debounceTime, forkJoin, Subject, Subscription, takeUntil } from 'rxjs';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import {
  Award,
  Dog,
  DogPedigree,
  DogTableResponse,
  Health,
  HealthResponse,
  Offspring2,
  SiblingResponse
} from '../models/dog.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DogService } from '../services/dog.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ModalService } from '../services/modal.service';
import { DateTime } from 'luxon';
import { environment } from '../../environments/environment';
import { ModalLargeService } from '../services/modal-large.service';

@Component({
  selector: 'app-searchsmall',
  templateUrl: './searchsmall.component.html',
  styleUrls: ['./searchsmall.component.scss']
})
export class SearchsmallComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, {static : false}) dtElement: DataTableDirective | undefined;
  @ViewChild('pedigreeComp') pedigreeComp: PedigreeComponent | undefined;
  @ViewChild('tooltipcontent') tooltipcontent: PopperContent | undefined;
  dtTrigger: Subject<ADTSettings> = new Subject();
  dogs: DogTableResponse[] = [];
  totalDogsFound = 0;
  noData = true;
  dogForm: FormGroup;
  searchTerm = '';
  selectedDogId = -1;
  showTabs = false;
  showDataReaderSpinner = false;
  loading = 'showTable';
  currentTabName = 'Profile';
  private allowChangingLength = true;
  private allowPaging = true;
  private allowInfo = true;
  selectedDogInfo: Dog;
  selectedDogPedigree: DogPedigree;
  selectedDogAwards: Award[] = [];
  selectedDogHealth: HealthResponse = {health: [{}], conditions: [{}], conditionsCount: 0};
  selectedDogOffspring: Offspring2[] = [];
  selectedDogSiblings: SiblingResponse;
  searchPanelButtonName = 'Hide Search Panel';
  searchPanelOpen = true;
  showDogTableSpinner = false;
  userIsLoggedIn = false;
  private unsubscribe$ = new Subject<void>();
  dropdownValue = "contains:";

  constructor(private ngZone: NgZone,
              private service: DogService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private tokenService: TokenStorageService,
              private breadcrumbs: BreadcrumbsService,
              private clipboard: Clipboard,
              private modalService: ModalService,
              private modalLargeService: ModalLargeService,
              private fb: FormBuilder) {
    this.dogForm = this.fb.group({
      search_name: [''],
      qualifier: ['contain']
    });
    this.selectedDogInfo = { registry_info: [] };
    this.selectedDogPedigree = {acl3: 0, acl4: 0, pedigree: {}};
    this.selectedDogSiblings = {fullSiblings: [], damHalfSiblings: [], sireHalfSiblings: []};
    if (window.innerWidth > 480) {
      this.router.navigateByUrl('search');
    }}

  dtOptions: ADTSettings = {};
  sub: Subscription | undefined;

  ngAfterViewInit(): void {
    this.dtTrigger.next(this.dtOptions);
  }

  login() {
    this.tokenService.setRedirectUrl('searchsmall');
    this.router.navigateByUrl('login');
  }

  logout() {
    this.tokenService.signOut();
    window.location.reload();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.dtTrigger.unsubscribe();
    this.sub?.unsubscribe();
  }
  ngOnInit() {
    this.sub = this.activatedRoute.queryParamMap
      .subscribe(params => {
        const theDog = params.get('dogId');
        if ((theDog !== null) && (+theDog > 0)) {
          this.breadcrumbs.pushDog(+theDog, 'Unknown');
        }
      })
    this.tokenService.getLoggedInNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.userIsLoggedIn = res;
        });
      });
    this.tokenService.getHasTokenNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res) {
          let curDog = this.breadcrumbs.getCurrentDog();
          if (curDog.dogId !== 0) {
            this.someClickHandler({dog_id: curDog.dogId, name: 'Unknown'}, false);
          }
          }
        });
      });
    this.onChanges();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      searching: false,
      lengthChange: true,
      paging: true,
      info: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.ngZone.run(() => {
          const searchTerm = this.dogForm.get('search_name')?.value;
          if ((searchTerm.length >= 3) && !this.showTabs) {
            this.showDogTableSpinner = true;
            const shouldStartWith = this.dogForm.get('qualifier')?.value;
            const userData = { qualifier: shouldStartWith};
            console.log('getting dog data table');
            this.service.getDogTable(Object.assign(dataTablesParameters, userData))
              .subscribe(
                resp => {
                this.showDogTableSpinner = false;
                if (resp.hasOwnProperty('data')) {
                  this.dogs = resp.data;
                  this.totalDogsFound = resp.recordsFiltered;
                  if (this.dogs.length > 0) {
                    this.noData = false;
                  } else {
                    this.noData = true;
                  }
                } else {
                  resp['data'] = [];
                  resp['recordsTotal'] = 0;
                  resp['recordsFiltered'] = 0;
                }

                callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.data
                });
              },
                error => {
                  this.showDogTableSpinner = false;
                  this.modalService.open('modal-search-error');
                }
              );
          } else {
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data:[]
            });
          }
        });
      },
      order: [[4, 'asc']],
      columns: [
        {
          title: 'Registered Name',
          data: 'registered_name'
        }, {
          title: 'Call Name',
          data: 'call_name'
        }, {
          title: 'Registration ID',
          data: 'registry_id'
        }, {
          title: 'Date of Birth',
          data: 'date_of_birth'
        }, {
          title: 'ID',
          data: 'dog_id',
          visible: false
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.someClickHandler(data);
        });
        let rowData = data as DogTableResponse;
        let datenumeric: any;
        datenumeric = rowData.date_of_birth;
        const dateDate = DateTime.fromFormat(datenumeric, 'M/d/yyyy');
        $('td:eq(3)', row).text(dateDate.toLocaleString(DateTime.DATE_FULL));
        return row;
      }
    };
  }

  someClickHandler(info: any, pushDog: boolean = true): void {
    /*
    this.selectedDonor = {identity: info.identity};
    const dState: DonorState = {donor: this.selectedDonor, enhanced: info};
    this.store.dispatch(new donorActions.SetSelectedDonorAction(dState));
    this.router.navigateByUrl('/app/donor-details');
    */
    if (pushDog) {
      this.breadcrumbs.pushDog(info.dog_id, info.registered_name);
    }
    this.showDataReaderSpinner = true;
    forkJoin({
      dogData: this.service.getDogInfo(info.dog_id),
      dogPed: this.service.getDogPedigree(info.dog_id, 4),
      dogAwards: this.service.getDogAwards(info.dog_id),
      dogHealth: this.service.getDogHealth(info.dog_id, true),
      dogOffspring: this.service.getDogOffspring2(info.dog_id),
      dogSiblings: this.service.getDogSiblings(info.dog_id)
    })
      .subscribe(
        (data) => {
        this.showDataReaderSpinner = false;
        if (data.dogData.hasOwnProperty('dog_id')) {
          this.selectedDogInfo = data.dogData;
          this.breadcrumbs.updateDogInfo(this.selectedDogInfo.dog_id, this.selectedDogInfo.registered_name);
        }
        this.selectedDogPedigree = data.dogPed;
        if (data.dogHealth.hasOwnProperty('health')) {
          this.selectedDogHealth = data.dogHealth;
        } else {
          this.selectedDogHealth = {health: [{}], conditions: [{}], conditionsCount: 0};
        }
        if (data.dogAwards.hasOwnProperty('allAwards')) {
          this.selectedDogAwards = data.dogAwards.allAwards;
        } else {
          this.selectedDogAwards = [];
        }
        if (data.dogOffspring.hasOwnProperty('offspring')) {
          this.selectedDogOffspring = data.dogOffspring.offspring;
        } else {
          this.selectedDogOffspring = [];
        }
        if (data.dogSiblings.hasOwnProperty('fullSiblings')) {
          this.selectedDogSiblings = data.dogSiblings;
        } else {
          this.selectedDogSiblings = {fullSiblings: [], sireHalfSiblings: [], damHalfSiblings: []};
        }
        const tabEl = document.querySelector('a[data-bs-target="#v-pills-profile"]');
        if (tabEl !== null) {
          tabEl.classList.add('active');
        }
        const tabEl2 = document.querySelector('a[data-bs-target="#v-pills-pedigree"]');
        if (tabEl2 !== null) {
          tabEl2.classList.remove('active');
        }
        const tabEl3 = document.querySelector('a[data-bs-target="#v-pills-offspring"]');
        if (tabEl3 !== null) {
          tabEl3.classList.remove('active');
        }
        const tabEl4 = document.querySelector('a[data-bs-target="#v-pills-siblings"]');
        if (tabEl4 !== null) {
          tabEl4.classList.remove('active');
        }
        const tabEl5 = document.querySelector('a[data-bs-target="#v-pills-health"]');
        if (tabEl5 !== null) {
          tabEl5.classList.remove('active');
        }
        const tabEl6 = document.querySelector('a[data-bs-target="#v-pills-awards"]');
        if (tabEl6 !== null) {
          tabEl6.classList.remove('active');
        }
        const paneEl = document.getElementById('v-pills-profile');
        if (paneEl !== null) {
          paneEl.classList.add('active', 'show');
        }
        const paneEl2 = document.getElementById('v-pills-pedigree');
        if (paneEl2 !== null) {
          paneEl2.classList.remove('active', 'show');
          this.pedigreeComp?.removeLines();
        }
        const paneEl3 = document.getElementById('v-pills-offspring');
        if (paneEl3 !== null) {
          paneEl3.classList.remove('active', 'show');
        }
        const paneEl4 = document.getElementById('v-pills-siblings');
        if (paneEl4 !== null) {
          paneEl4.classList.remove('active', 'show');
        }
        const paneEl5 = document.getElementById('v-pills-health');
        if (paneEl5 !== null) {
          paneEl5.classList.remove('active', 'show');
        }
        const paneEl6 = document.getElementById('v-pills-awards');
        if (paneEl6 !== null) {
          paneEl6.classList.remove('active', 'show');
        }
        this.selectedDogId = info.id;
        this.showTabs = true;
        this.allowPaging = false;
        this.allowChangingLength = false;
        this.allowInfo = false;
        this.rerender();
        this.loading = 'hideTable';
      },
        (error) => {
          this.tokenService.signOut();
          this.showDataReaderSpinner = false;
          this.modalService.open('modal-search-error');
      })
  }

  returnToList() {
    this.breadcrumbs.clearDogTrack();
    this.selectedDogId = -1;
    this.showTabs = false;
    this.allowPaging = true;
    this.allowChangingLength = true;
    this.allowInfo = true;
    this.rerender();
    this.loading = 'showTable';
  }
  rerender(): void {
    this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      this.dtOptions.search = {search: this.searchTerm, regex: false};
      this.dtOptions.paging = this.allowPaging;
      this.dtOptions.lengthChange = this.allowChangingLength;
      this.dtOptions.info = this.allowInfo;
      // Call the dtTrigger to rerender again
      this.dtTrigger.next(this.dtOptions);
    });
  }

  onChanges(): void {
    this.dogForm.get('search_name')?.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(val => {
      this.searchTerm = val;
      this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
        });
      });
      this.rerender();
    });
    this.dogForm.get('qualifier')?.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(val => {
      this.dtElement?.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
        });
      });
      this.rerender();
    });
  }

  searchPanelChange() {
    this.searchPanelOpen = !this.searchPanelOpen;
    this.searchPanelButtonName = this.searchPanelOpen ? 'Hide Search Panel' : 'Show Search Panel';
  }

  getDogLink() {
    const success = this.clipboard.copy(environment.baseURL + '/search?dogId=' + this.selectedDogInfo.dog_id);
    if (success) {
      this.tooltipcontent?.show();
      setTimeout(() => {
        this.tooltipcontent?.hide();
      }, 2000);
    }
  }

  tabSelected(tabName: string) {
    this.currentTabName = tabName;
  }

  closeModal() {
    this.modalService.close();
  }
  closeLargeModal() {
    this.modalLargeService.close();
  }

  submitCorrection() {
    if (environment.production) {
      window.open('https://forms.ncanewfs.org/updatedog?dogId=' + this.selectedDogInfo.dog_id);
    } else {
      window.open('https://formstest.ncanewfs.org/updatedog?dogId=' + this.selectedDogInfo.dog_id);
    }
  }

  changeQueryType(event: any) {
    //const qual = document.getElementById('qualifier');
    //if (qual !== null) {
    this.dropdownValue = event?.target?.options[event.target.options.selectedIndex].text.toLowerCase();
    // }
  }

  showSummary() {
    this.modalLargeService.open('modal-search-summary');
  }
}
