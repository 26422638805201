import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
  withJsonpSupport,
  withXsrfConfiguration
} from '@angular/common/http';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { SearchComponent } from './search/search.component';
import { AboutComponent } from './about/about.component';
import { AwardsComponent } from './awards/awards.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FaqsComponent } from './faqs/faqs.component';
import { HealthComponent } from './health/health.component';
import { OffspringComponent } from './offspring/offspring.component';
import { PedigreeComponent } from './pedigree/pedigree.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProfileComponent } from './profile/profile.component';
import { ResultsComponent } from './results/results.component';
import { SiblingsComponent } from './siblings/siblings.component';
import { MoreWaysToConnectComponent } from './more-ways-to-connect/more-ways-to-connect.component';
import { WhyChooseUsComponent } from './why-choose-us/why-choose-us.component';
import { DataTablesModule } from 'angular-datatables';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SafePipe } from './safe.pipe';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleDown } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NgxPopperModule } from 'ngx-popper';
import { ModalComponent } from './modal/modal.component';
import { SearchsmallComponent } from './searchsmall/searchsmall.component';
import { DataDisplayComponent } from './data-display/data-display.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { AuthGuardService } from './services/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { CardComponent } from './card/card.component';
import { AdminModule } from './admin/admin.module';
import { AuthService } from './services/auth.service';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { DogService } from './services/dog.service';
import { ModalService } from './services/modal.service';
import { TokenStorageService } from './services/token-storage.service';
import { UserService } from './services/user.service';
import { SummaryComponent } from './summary/summary.component';
import { ModalLargeComponent } from './modal-large/modal-large.component';
import { SharedModule } from './shared/shared.module';
import { QueryModalHealthConditionComponent } from './query-modal/query-modal-health-condition.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { QueryModalDeathComponent } from './query-modal-death/query-modal-death.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { UpdateDogFormComponent } from './update-dog-form/update-dog-form.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageService } from './image-upload/image.service';
import { FileDropDirective } from './services/file-drop.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        AdminComponent,
        SearchComponent,
        AboutComponent,
        ContactsComponent,
        FaqsComponent,
        OffspringComponent,
        PrivacyComponent,
        ResultsComponent,
        SiblingsComponent,
        MoreWaysToConnectComponent,
        WhyChooseUsComponent,
        HeaderComponent,
        FooterComponent,
        SpinnerComponent,
        SafePipe,
        BreadcrumbComponent,
        SearchsmallComponent,
        CardComponent,
        ModalLargeComponent,
        QueryModalHealthConditionComponent,
        QueryModalDeathComponent,
        UpdateDogFormComponent,
        ImageUploadComponent,
        FileDropDirective
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxPopperModule.forRoot(),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    AdminModule,
    SharedModule,
    MatDialogModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    MatRadioModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    NgbInputDatepicker,
    MatTooltipModule,
    PdfViewerModule
  ],
    providers: [
        AuthGuardService, AuthService, BreadcrumbsService, DogService, ModalService, TokenStorageService, UserService, ImageService,
        authInterceptorProviders, provideHttpClient(
            withInterceptorsFromDi()
        )
    ],
  exports: [],
    bootstrap: [AppComponent]
})

export class AppModule {  constructor(library: FaIconLibrary) {
  library.addIcons(
    faCircleDown
  );
}
}
