import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import { Subject, takeUntil } from 'rxjs';
import { DogService } from '../services/dog.service';
import { ACommitteeMember } from '../models/dog.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  userIsLoggedIn = false;
  isAdmin = false;
  private unsubscribe$ = new Subject<void>();
  dbAdmins: ACommitteeMember[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private tokenService: TokenStorageService,
              private dogService: DogService,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    this.tokenService.getLoggedInNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.userIsLoggedIn = res;
          this.isAdmin = this.tokenService.isAdminUser();
        });
      });
//    this.router.navigate(['dashboard'], {relativeTo: this.route});
    this.dogService.getDbAdmins()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('committeeMembers')) {
            this.dbAdmins = res.committeeMembers;
          }
        });
      });
    this.dogService.loadDbAdmins();
  }

  logout() {
    this.tokenService.signOut();
    window.location.reload();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
