<div class="container-fluid mb-5">
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
    <div class="row d-flex">
      <div class="col-md-3 shadow-lg p-3">
        <h2>Instructions</h2>
        <ul>
          <li>Please enter additions and corrections on this form</li>
          <li>For any addition/correction, certification must be provided</li>
          <li>Additions and/or corrections can only be made by an owner or a breeder</li>
          <li>Registration certificate may be an AKC or foreign country registration form or certified pedigree</li>
          <li>Award certification may be an NCA, AKC, or foreign registry award certificate</li>
          <li>Health certification may be an OFA or open database certificate</li>
          <li>Dates of death and call names can be submitted without certification</li>
        </ul>
        <p>Please complete this form fully and it will be submitted to the Database Chairperson.</p>
        <p>The submit button will become active when you have completed all of the required fields.</p>
      </div>
      <div class="col-md-9">
        <mat-card>
          <mat-card-header>
            <div style="margin: 20px auto;">
              <mat-card-title class="large-title">NCA Database Update Form</mat-card-title>
            </div>
          </mat-card-header>
          <mat-card-content>

            <form [formGroup]="updateDogForm">
              <div class="row d-none">
                <div class="col-md-7" style="text-align: right;">
                  <label>Dog Id:</label>
                </div>
                <div class="col-md-5">
                  <input class="w-100 form-control form-control-sm" name="dogId" formControlName="dogId">
                </div>
              </div>
              <div class="row d-none">
                <div class="col-md-7" style="text-align: right;">
                  <label>Member Id:</label>
                </div>
                <div class="col-md-5">
                  <input class="w-100 form-control form-control-sm" name="submitterMemberId" formControlName="submitterMemberId">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="submitterName">Your Name:</label>
                  <input class="w-100 form-control form-control-sm" name="submitterName" id="submitterName" formControlName="submitterName">
                </div>
                <div class="col-md-6">
                  <label for="submitterEmail">Your Email:</label>
                  <input class="w-100 form-control form-control-sm" name="submitterEmail" id="submitterEmail" formControlName="submitterEmail">
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6 mx-auto table-responsive">
                  <h2 class="text-center">Current Dog Information:</h2>
                  <table class="table table-bordered table-striped">
                    <tr>
                      <td class="text-right font-weight-bold">Registered Name</td>
                      <td>{{ selectedDogTitles }}</td>
                    </tr>
                    <tr *ngFor="let reginfo of selectedDogInfo.registry_info; let index = index">
                      <td class="text-right font-weight-bold align-middle" *ngIf="index === 0" [attr.rowspan]="selectedDogInfo.registry_info.length">Registration Ids</td>
                      <td>{{ reginfo.registry_id }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Gender</td>
                      <td>{{ dogGender }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Date of Birth</td>
                      <td>{{ dogDOB }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Date of Death</td>
                      <td>{{ dogDOD }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Coat Color</td>
                      <td>{{ dogCoatColor }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Health</td>
                      <td>{{ selectedDogHealth }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Sire</td>
                      <td>{{ getSireInfo() }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Dam</td>
                      <td>{{ getDamInfo() }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Owner</td>
                      <td>{{ selectedDogInfo.owner?.owner_name }}</td>
                    </tr>
                    <tr>
                      <td class="text-right font-weight-bold">Breeder</td>
                      <td>{{ getBreederInfo() }}</td>
                    </tr>

                  </table>
                </div>
              </div>

              <div class="row mt-3 mb-2 pb-2 align-items-baseline border-bottom">
                <div class="col-md-12">
                  <p><span class="fw-bold fs-3">1. What information are you requesting to change:</span><br/><span>Use te Details text area below to provide details on your request</span></p>
                </div>
              </div>
              <div class="row align-items-baseline">
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input border-dark" type="checkbox" value="CallName" id="callName" formControlName="callName"
                           (change)="selectedActionChanged()">
                    <label class="form-check-label" for="callName">
                      Add Call Name
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input border-dark" type="checkbox" value="SireDam" id="sireDam" formControlName="sireDam"
                           (change)="selectedActionChanged()">
                    <label class="form-check-label" for="sireDam">
                      Update Sire or Dam Information
                    </label>
                  </div>
                </div>
              </div>
              <div class="row align-items-baseline">
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input border-dark" type="checkbox" value="Titles" id="titles" formControlName="titles"
                           (change)="selectedActionChanged()">
                    <label class="form-check-label" for="titles">
                      Missing or Incorrect Titles
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input border-dark" type="checkbox" value="Health" id="health" formControlName="health"
                           (change)="selectedActionChanged()">
                    <label class="form-check-label" for="health">
                      Missing or Incorrect Health Clearances
                    </label>
                  </div>
                </div>
              </div>
              <div class="row align-items-baseline">
                <div class="col-md-3">
                  <div class="form-check">
                    <input class="form-check-input border-dark" type="checkbox" value="Date_of_birth" id="dob" formControlName="dob"
                           (change)="dateOfBirthChanged()">
                    <label class="form-check-label" for="dob">
                      Update Date of Birth
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="input-group">
                    <input
                      class="form-control"
                      formControlName="dateOfBirth"
                      [class.is-invalid]="updateDogForm.controls['dateOfBirth'].invalid"
                      placeholder="yyyy-mm-dd"
                      name="dp"
                      [(ngModel)]="dateOfBirthModel"
                      navigation="select"
                      ngbDatepicker
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      #db="ngbDatepicker"
                    />
                    <button class="btn btn-outline-secondary bi bi-calendar3" [disabled]="isDobDisabled()" (click)="db.toggle()" type="button"></button>
                    <mat-error *ngIf="updateDogForm.controls['dateOfBirth'].touched && updateDogForm.controls['dateOfBirth'].hasError('required')">This field is <strong>required</strong></mat-error>
                    <div class="invalid-tooltip">{{ updateDogForm.controls['dateOfBirth'].invalid ? "Date is invalid" : undefined }}</div>
                  </div>
                </div>
              </div>
              <div class="row align-items-baseline">
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input border-dark" type="checkbox" value="Other" id="other" formControlName="other"
                           (change)="selectedActionChanged()">
                    <label class="form-check-label" for="other">
                      Correct Other Information
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mt-3 mb-2 pb-2 align-items-baseline border-bottom">
                <div class="col-md-2">
                  <label class="fw-bold">Details:</label>
                </div>
                <div class="col-md-10">
                  <textarea class="w-100 form-control form-control-sm" formControlName="dogNotes" rows="4"
                            placeholder="Please describe what, if anything, is incorrect or missing in the above dog data"></textarea>
                  <mat-error *ngIf="updateDogForm.controls['dogNotes'].touched && updateDogForm.controls['dogNotes'].hasError('required')">This field is <strong>required</strong></mat-error>
                </div>
              </div>
              <div class="row mt-4 align-items-baseline">
                <div class="col-md-12">
                  <div class="border-1">
                    <div class="row">
                      <p>You must be the owner or breeder of this dog in order to supply this information.  The date of death will be public information but the health condition and cause of death information
                        provided here will not be visible and will only be used for research purposes unless the box below is checked to allow the information to be displayed.
                        The receiver of this form will not see the values you entered, but will verify that you are the owner or breeder before accepting your submission.</p>
                    </div>
                    <div class="row border-top pt-1">
                      <div class="col-md-3">
                        <p class="w-100">&nbsp;</p>
                      </div>
                      <div class="col-md-3">
                        <p class="w-100">&nbsp;</p>
                      </div>
                      <div class="col-md-3">
                        <p class="w-100">&nbsp;</p>
                      </div>
                      <div class="col-md-3">
                        <p class="w-100">If other, please provide details</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-check">
                          <input class="form-check-input border-dark" type="checkbox" value="AddHealth" id="addHealth" formControlName="addHealth"
                                 (change)="healthConditionChanged()">
                          <label class="form-check-label" for="addHealth">
                            Add a Health Condition
                          </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <select class="w-100" matNativeControl formControlName="healthCategory" (change)="updateHealthOptions()">
                          <option value="">Select</option>
                          <option *ngFor="let category of healthCategoryNames; let i = index" [value]="category">{{category}}</option>
                        </select>
                        <mat-error *ngIf="updateDogForm.controls['healthCategory'].touched && updateDogForm.controls['healthCategory'].hasError('required')">This field is <strong>required</strong></mat-error>
                      </div>
                      <div class="col-md-3">
                        <select class="w-100" matNativeControl formControlName="healthCategoryOption" (change)="updateHealthOptionsOther()">
                          <option value="">Select</option>
                          <option *ngFor="let anOption of healthCategoryOptionNames; let i = index" [value]="anOption">{{anOption}}</option>
                        </select>
                        <mat-error *ngIf="updateDogForm.controls['healthCategoryOption'].touched && updateDogForm.controls['healthCategoryOption'].hasError('required')">This field is <strong>required</strong></mat-error>
                      </div>
                      <div class="col-md-3">
                        <input type="text" matInput class="w-100" name="healthOther" id="healthOther" formControlName="healthOther">
                        <mat-error *ngIf="updateDogForm.controls['healthOther'].hasError('required')">This field is <strong>required</strong></mat-error>
                        <mat-error *ngIf="updateDogForm.controls['healthOther'].hasError('maxLength')">This field is <strong>limited to 256 characters</strong></mat-error>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-10">
                        <div class="form-check">
                          <input class="form-check-input border-dark" type="checkbox" value="HealthPublic" id="healthConditionPublic" formControlName="healthConditionPublic">
                          <label class="form-check-label" for="healthConditionPublic">
                            <span class="fw-bold">Make this health condition public:</span> If you check this checkbox, this health condition will be visible on the dog database website
                            listing for your dog.  It is off by default. Only check this checkbox if you are comfortable sharing this information
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row border-bottom pb-2">
                      <div class="col-md-1"></div>
                      <div class="col-md-10">
                        <div class="form-check">
                          <input class="form-check-input border-dark" type="checkbox" value="death" id="death" formControlName="death">
                          <label class="form-check-label" for="death">
                            <span class="fw-bold">This health condition was the cause of the death of this dog:</span>  Check this checkbox if you want to record this health condition
                            as the cause of death of this dog. If not checked, this health condition will be added to the dog record but not associated with the
                            cause of death.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-baseline">
                      <div class="col-md-3">
                        <div class="form-check">
                          <input class="form-check-input border-dark" type="checkbox" value="Date_of_death" id="dod" formControlName="dod"
                                 (change)="dateOfDeathChanged()">
                          <label class="form-check-label" for="dod">
                            Update Date of Death
                          </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="input-group">
                          <input
                            class="form-control"
                            formControlName="dateOfDeath"
                            [class.is-invalid]="updateDogForm.controls['dateOfDeath'].invalid"
                            placeholder="yyyy-mm-dd"
                            name="dp"
                            [(ngModel)]="dateOfDeathModel"
                            navigation="select"
                            ngbDatepicker
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            #dd="ngbDatepicker"
                          />
                          <button class="btn btn-outline-secondary bi bi-calendar3" [disabled]="isDodDisabled()" (click)="dd.toggle()" type="button"></button>
                          <mat-error *ngIf="updateDogForm.controls['dateOfDeath'].touched && updateDogForm.controls['dateOfDeath'].hasError('required')">This field is <strong>required</strong></mat-error>
                          <div class="invalid-tooltip">{{ updateDogForm.controls['dateOfDeath'].invalid ? "Date is invalid" : undefined }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-2 pb-2 align-items-baseline border-bottom">
                <div class="col-md-12">
                  <label class="fw-bold fs-3">2. Provide Documentation (if necessary):</label>
                </div>
              </div>
              <div class="row mb-2 pb-2 align-items-baseline">
                <div class="col-md-12">
                  <h5 class="text-left">Upload a photo of your certified pedigree and/or AKC registration certificate.</h5>
                  <h5 class="text-left">You may upload a maximum of 2 files.</h5>
                  <p class="text-left">Maximum file size = 20MB. Supported file types: JPG, PNG, PDF, TIFF.</p>

                  <div>
                    <div class="row">
                      <div class="col-md-12">
                        <app-image-upload
                          [max]="2" class="customUploadClass"
                          [extensions]="['image/jpg','image/png','image/jpeg','application/pdf','image/tif','image/tiff']"
                          [url]="'https://images.ncanewfs.org/blob_upload'"
                          [buttonCaption]="'Select Files to Upload'"
                          [registrationNumber]=""
                          [partName]="'blob'"
                          [serviceType]="'UpdateDog'"
                          [filePurpose]="'certificatePhoto'"
                          [issueId]="'all'"
                          [filebase]="'ud-'"
                          [maxFileSize]="20 * 1024 * 1024"
                          (uploadFinished)="onUploadFinished($event)"
                          (removed)="onRemove($event)"
                          [dropBoxMessage]="'Drop your images here.'">
                        </app-image-upload>

                      </div>
                    </div>
                    <div class="row">
                      <div *ngIf="uploadFailed" style="color: red;font-size: large;">
                        <p>An error occurred while uploading your file. {{ customErrorMessage }}. Please make sure that your image is of the correct file type and smaller than the maximum size and try again.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class=" d-none row mt-2">
                <div class="col-md-10">
                  <button type="button" class="btn btn-primary" style="border-radius: 20px;" data-bs-toggle="collapse" data-bs-target="#pedigreePanel" (click)="pedigreeChange()">
                    <span>{{ showHidePedigreeButtonName }}</span>
                  </button>
                  <div id="pedigreePanel" class="collapse mb-3">
                    <div class="panel panel-default card p-3">
                      <div class="panel-body">
                        <div class="row mb-2 align-items-baseline">
                          <div class="col-md-10 mx-auto">
                            <table class="table table-bordered table-hover">
                              <tr>
                                <td class="align-middle" rowspan="8" [innerHTML]="dogName"></td>
                                <td [ngClass]="getPedigreeBackgroundColor('Dog', dogSireName)" rowspan="4" [innerHTML]="dogSireName"></td>
                                <td [ngClass]="getPedigreeBackgroundColor('Dog', dogSireSireName)" rowspan="2" [innerHTML]="dogSireSireName"></td>
                                <td [ngClass]="getPedigreeBackgroundColor('Dog', dogSireSireSireName)" [innerHTML]="dogSireSireSireName"></td>
                              </tr>
                              <tr>
                                <td [ngClass]="getPedigreeBackgroundColor('Dam', dogSireSireDamName)" [innerHTML]="dogSireSireDamName"></td>
                              </tr>
                              <tr>
                                <td [ngClass]="getPedigreeBackgroundColor('Dam', dogSireDamName)" rowspan="2" [innerHTML]="dogSireDamName"></td>
                                <td [ngClass]="getPedigreeBackgroundColor('Dog', dogSireDamSireName)" [innerHTML]="dogSireDamSireName"></td>
                              </tr>
                              <tr>
                                <td [ngClass]="getPedigreeBackgroundColor('Dam', dogSireDamDamName)" [innerHTML]="dogSireDamDamName"></td>
                              </tr>
                              <tr>
                                <td [ngClass]="getPedigreeBackgroundColor('Dam', dogDamName)" rowspan="4" [innerHTML]="dogDamName"></td>
                                <td [ngClass]="getPedigreeBackgroundColor('Dog', dogDamSireName)" rowspan="2" [innerHTML]="dogDamSireName"></td>
                                <td [ngClass]="getPedigreeBackgroundColor('Dog', dogDamSireSireName)" [innerHTML]="dogDamSireSireName"></td>
                              </tr>
                              <tr>
                                <td [ngClass]="getPedigreeBackgroundColor('Dam', dogDamSireDamName)" [innerHTML]="dogDamSireDamName"></td>
                              </tr>
                              <tr>
                                <td [ngClass]="getPedigreeBackgroundColor('Dam', dogDamDamName)" rowspan="2" [innerHTML]="dogDamDamName"></td>
                                <td [ngClass]="getPedigreeBackgroundColor('Dog', dogDamDamSireName)" [innerHTML]="dogDamDamSireName"></td>
                              </tr>
                              <tr>
                                <td [ngClass]="getPedigreeBackgroundColor('Dam', dogDamDamDamName)" [innerHTML]="dogDamDamDamName"></td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <label class="fw-bold fs-3">3. Submit Form</label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button mat-button type="button" class="btn btn-formSubmit" (click)="submitForm()">Submit Form</button>
                </div>
              </div>
            </form>

          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row d-block d-lg-none d-xl-none">
      <div class="col-12">
        <h4>Sorry, this form is not optimized for use on this device.  Please use a desktop or laptop browser to continue with this form.</h4>
      </div>
    </div>
    <app-modal id="modal-update-error">
      <div class="modal-header">
        <h5 class="modal-title">Unable to retrieve data</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <p>An error occurred while trying to retrieve the data for this dog.  Please try again.</p>
        <p>If this error continues to occur, please send an email to <a href="mailto:dan@dogfishsoftware.com">dan@dogfishsoftware.com</a> and provide the dog's name that was being searched.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal();">Close</button>
      </div>

    </app-modal>
    <app-modal id="modal-update-thanks">
      <div class="modal-header">
        <h5 class="modal-title">Thanks</h5>
        <button type="button" class="btn-close" (click)="closeThanksModal()"></button>
      </div>
      <div class="modal-body">
        <p>Thanks for submitting a dog correction form.  You will receive an email confirmation to verify the submission, please check your spam or junk mail folders if you do not receive a confirmation email.</p>
        <p>You will now be returned to the search screen for the dog you were looking at.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeThanksModal();">Close</button>
      </div>

    </app-modal>
  </div>
</div>
