import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SiblingsComponent } from './siblings/siblings.component';
import { ResultsComponent } from './results/results.component';
import { ProfileComponent } from './profile/profile.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PedigreeComponent } from './pedigree/pedigree.component';
import { OffspringComponent } from './offspring/offspring.component';
import { HealthComponent } from './health/health.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ContactsComponent } from './contacts/contacts.component';
import { AwardsComponent } from './awards/awards.component';
import { SearchsmallComponent } from './searchsmall/searchsmall.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DashComponent } from './admin/dash/dash.component';
import { DogsComponent } from './admin/dogs/dogs.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { SystemComponent } from './admin/system/system.component';
import { FormDetailsComponent } from './admin/form-details/form-details.component';
import { UpdateDogFormComponent } from './update-dog-form/update-dog-form.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '', component: DashComponent
      },
      {
        path: 'dashboard',
        component: DashComponent
      },
      {
        path: 'dogs',
        component: DogsComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'system',
        component: SystemComponent
      },
      {
        path: 'form-details/:id',
        component: FormDetailsComponent
      }
    ]
  },
  { path: 'about', component: AboutComponent },
  { path: 'search', component: SearchComponent },
  { path: 'searchsmall', component: SearchsmallComponent},
  { path: 'siblings', component: SiblingsComponent },
  { path: 'results', component: ResultsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'pedigree', component: PedigreeComponent },
  { path: 'offspring', component: OffspringComponent },
  { path: 'health', component: HealthComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'awards', component: AwardsComponent },
  { path: 'form/:dogId', component: UpdateDogFormComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
