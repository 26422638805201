import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { DogBreadcrumb } from '../models/dog.model';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  trackedDogs: DogBreadcrumb[];
  private unsubscribe$ = new Subject<void>();

  constructor(private breadcrumbs:BreadcrumbsService,
              private router: Router,
              private ngZone: NgZone) {
    this.trackedDogs = this.breadcrumbs.getCurrentDogArray();
  }

  gotoDog(dog: DogBreadcrumb) {
    this.breadcrumbs.popToDog(dog.dogId);
    this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
    this.router.navigateByUrl('/search');
  }

  isLastDog(dog: DogBreadcrumb) {
    if (this.trackedDogs.length > 0) {
      let lastDog = this.trackedDogs[this.trackedDogs.length - 1];
      return lastDog.dogId === dog.dogId;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.breadcrumbs.getCurrentBreadcrumbNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.trackedDogs = res;
        });
      });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
