<div *ngIf="!hideFooter" class="footer text-center mt-3">
  <div class="social-icons">
    <a href="https://www.facebook.com/NCANewfs"><i class="facebook"></i></a>
    <a href="https://twitter.com/NCANewfs"><i class="twitter"></i></a>
    <a href="https://plus.google.com/u/0/b/112471556172620326072/112471556172620326072/videos"><i class="googleplus"></i></a>
    <a href="https://www.youtube.com/user/NewfClubofAmerica"><i class="youtube"></i></a>
  </div>
  <div class="copyright">
    <p>
      <a href="https://www.ncanewfs.org">NCA Home</a>

      • <a href="https://www.ncanewfs.org/privacy.html">Privacy</a> • <a href="https://www.ncanewfs.org/legal.html">Legal</a> • <a href="https://www.ncanewfs.org/contacts.html">Contact</a> • Copyright &copy; 2023 </p>
  </div>
</div>
