<h2 mat-dialog-title>{{ prompt }}</h2>
<form [formGroup]="healthEditForm" id="profile-edit-form" class="input-group" role="form">

  <mat-dialog-content style="height: 400px;">
    <div class="row mx-auto">
      <div class="col-md-8">
        <select matNativeControl formControlName="causeOfDeathCategory" (change)="updateHealthOptions()">
          <option value="">Select</option>
          <option *ngFor="let category of healthCategoryNames; let i = index" [value]="category">{{category}}</option>
        </select>
      </div>
    </div>

    <div class="row mx-auto">
      <div class="col-md-8">
        <select matNativeControl #queryNameInput formControlName="causeOfDeathCategoryOption">
      <option value="">Select</option>
      <option *ngFor="let anOption of healthCategoryOptionNames; let i = index" [value]="anOption">{{anOption}}</option>
    </select>
      </div>
    </div>
    <div class="row row-cols-sm-auto">
      <div class="col-md-8 mx-auto">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="dp"
            [(ngModel)]="dateOfDeathModel"
            [ngModelOptions]="{standalone: true}"
            navigation="select"
            ngbDatepicker
            #d="ngbDatepicker"
          />
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
        </div>
        <!--
        <mat-form-field>
          <mat-label>Date of Death</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateOfDeath">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div *ngIf="submitted && updateDogForm.controls['dateOfDeath'].errors" class="invalid-feedback">
          <div *ngIf="updateDogForm.controls['dateOfDeath'].errors['required']">This field is required</div>
        </div>
        -->
      </div>
    </div>
  </mat-dialog-content>
</form>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" (click)="onSubmit(queryNameInput.value)">Save</button>
</mat-dialog-actions>
