<div class="latest-news">
  <div class="container">
    <div class="row">
      <header>
        <h3>More Ways to Connect</h3>
      </header>
    </div>
    <div class="row collection-row">
      <div class="col-md-4 grid_7">
        <div class="element">
          <img src="assets/images/NT4Q0911.jpg" alt="Newfoundland puppy">
          <h5><a href="https://lp.constantcontactpages.com/su/uI5c3jM/lifestages">Lifestages</a></h5><p>Educational Newsletter for Puppy Families</p>
        </div>
      </div>
      <div class="col-md-4 grid_7">
        <div class="element">
          <img src="assets/images/bredcorcover.jpg" alt="Newfoundland puppies">
          <h5><a href="https://lp.constantcontactpages.com/su/ffGVHSQ/breeders">Breeders Corner</a></h5><p>Support for Preservation Breeders</p>
        </div>
      </div>
      <div class="col-md-4 grid_7">
        <div class="element">
          <img src="assets/images/draft9cover.jpg" alt="Newfoundland Draft Dog">
          <h5><a href="https://lp.constantcontactpages.com/su/q8ENb0s/working">Working Dog News</a></h5><p>Water & Draft Training, Tests and Judging</p>
        </div>
      </div>
    </div>
    <div class="row collection-row">
      <div class="col-md-4 grid_7">
        <div class="element">
          <img src="assets/images/penny1.jpg" alt="newfoundland dog">
          <h5><a href="https://lp.constantcontactpages.com/su/LhJI3Uz/charities">NewfCare: Research & Rescue</a></h5><p>News from our Charitable Trust</p>
        </div>
      </div>
      <div class="col-md-4 grid_7">
        <div class="element">
          <img src="assets/images/fivedaycover.jpg" alt="newfoundland dog">
          <h5><a href="https://www.facebook.com/NCANewfs">NCA on Facebook™</a></h5><p>Like our Page</p>
        </div>
      </div>
      <div class="col-md-4 grid_7">
        <div class="element">
          <img src="assets/images/instacover.jpg" alt="newfoundland puppy">
          <h5><a href="https://www.instagram.com/ncanewfs/">NCA on Instagram™</a></h5><p>Follow our Feed</p>
        </div>
      </div>
    </div>
  </div>
</div>
