<div class="content text-center">
  <div>
    <div class="container-fluid table-container">
      <div *ngIf="!summary" class="grid_12">
        <header>
          <h4>Registered Name: {{ _dog?.registered_name }}</h4>
        </header>
      </div>
      <div *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="float-end">
        <button class="btn btn-secondary" (click)="addRecord()">Add New Award</button>
      </div>
      <div class="grid_12 table-responsive overflow-y-hidden" style="display: block;clear: both;content: '';">
        <table [ngClass]="getAwardsClass()">
          <thead>
          <tr>
            <th>Title</th>
            <th>Date</th>
            <th>Organization</th>
            <th *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval">Edit Award</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let award of _awards">
              <td>{{ award.award_code }}</td>
              <td>{{ award.award_date }}</td>
              <td>{{ award.organization_code }}</td>
              <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"><a class="p-2" href="" (click)="editAward(award)">Edit</a></td>
            </tr>
          <tr *ngIf="_awards.length === 0">
            <td colspan="3">No award data found</td>
          </tr>
          </tbody>
        </table>
        <div  *ngIf="!summary">
          <app-data-display></app-data-display>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
