<div class="content text-center">
  <div>
    <div class="container-fluid" style="padding: 0 50px;">

      <div class="grid_12">
        <header *ngIf="!summary">
          <h4>Registered Name: {{ _dog?.registered_name }}</h4>
        </header>
        <div *ngIf="!summary" class="row pictures-row">
          <div *ngIf="hasPictures" class="col-md-10">
            <ng-container *ngFor="let image of dogPictures">
              <img class="img-responsive img-thumbnail" src="{{image.src}}" alt="Dog Image">
            </ng-container>
          </div>
          <div *ngIf="!hasPictures" class="col-md-10 text-center mx-auto">
            <p class="p-3" >Pictures feature - Coming Soon!.</p>
          </div>
        </div>
        <div *ngIf="!summary" class="row dogInfo-row">
          <div class="col-md-4">
            <h5>Dog Info</h5>
            <p><span class="title">Call Name:</span><span class="content">{{ _dog?.call_name }}</span></p>
            <p class="title-small"><span class="title">All Registration Numbers, Organizations</span></p>
            <p *ngFor="let regInfo of _dog?.registry_info">
              <span class="content">{{ regInfo.registry_id }}, {{ regInfo.registry_organization }}</span>
            </p>
            <p><span class="title">Gender:</span><span class="content">{{ dogGender }}</span></p>
            <p><span class="title2">Date of Birth:</span><span class="title2 ms-5">Date of Death:</span><br><span class="content">{{ dogDOB }}</span><span class="content2">{{ dogDOD }}</span></p>
            <p><span class="title">Coat Color:</span><span class="content">{{ dogCoatColor }}</span></p>
            <p><span class="title">ROM Points:</span><span class="content">{{ getRomPoints() }}</span></p>
            <p><span class="title">ALC3:</span><span class="content">{{ getALC3() }}</span></p>
            <p><span class="title">ALC4:</span><span class="content">{{ getALC4() }}</span></p>
            <p><span class="title">Profile Id:</span><span class="content">{{ _dog?.dog_id }}</span> </p>
          </div>
          <div class="col-md-4">
            <h5>Sire and Dam Info</h5>
            <p (click)="gotoSireDog()"><span class="title">Sire Registered Name:</span><span class="content link">{{ getSireInfo() }}</span></p>
            <p><span class="title">Sire Registry ID:</span><span class="content">{{ _dog?.sireInfo?.registry_id }}</span></p>
            <!-- <p><span class="title">Sire Country of Birth:</span><span class="content">{{ _dog?.sireInfo?.origin_country }}</span></p> -->
            <p (click)="gotoDamDog()"><span class="title">Dam Registered Name:</span><span class="content link">{{ getDamInfo() }}</span></p>
            <p><span class="title">Dam Registry ID:</span><span class="content">{{ _dog?.damInfo?.registry_id }}</span></p>
            <!-- <p><span class="title">Dam Country of Birth:</span><span class="content">{{ _dog?.damInfo?.origin_country }}</span></p> -->
          </div>
          <div class="col-md-4">
            <h5>Breeder and Owner Info</h5>
            <p><span class="title">Breeder Name:</span><span class="content">{{ getBreederInfo() }}</span></p>
            <p><span class="title">Owner Name:</span><span class="content">{{ _dog?.owner?.owner_name }}</span></p>
            <p><span class="title">Notes:</span><span class="content"><p *ngFor="let note of _dog?.notes">{{ note.notes }}</p></span></p>
          </div>
        </div>
        <div *ngIf="summary" class="row dogInfo-row">
          <div class="col-md-12 table-responsive overflow-y-hidden">
            <h5>Dog Info</h5>
            <form [formGroup]="profileEditForm" id="profile-edit-form" class="input-group" role="form">
              <table class="table table-sm table-bordered table-striped table-hover mt-4 summary-table">
                <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                  <th *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval">Updated/Provided Value</th>
                  <th *ngIf="isSuperAdmin">Approvals </th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Call Name</td><td>{{ _dog?.call_name }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100">
                      <input type="text" class="form-control form-control-sm" formControlName="call_name">
                      <div *ngIf="!editNeedsApproval('call_name') && editMode === EditModes.editable">
                        <a class="p-2" (click)="submitField('call_name',_dog?.call_name)">Submit</a>
                      </div>
                      <div *ngIf="editNeedsApproval('call_name')">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="editNeedsApproval('call_name')">
                        <a class="p-2" (click)="approveOrDenyField('call_name', true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyField('call_name', false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td [rowSpan]="registryCount + 1">Registration Numbers</td><td>Number, Organization</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                  <tr *ngFor="let regInfo of _dog?.registry_info;let index = index; trackBy: trackByIndex">
                    <td>{{ regInfo.registry_id }}, {{ regInfo.registry_organization }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100">
                      <div *ngIf="!regNeedsApproval(regInfo)">
                        <button class="btn btn-primary btn-sm" (click)="editReg(regInfo)">Edit Registration</button>
                      </div>
                      <div *ngIf="regNeedsApproval(regInfo)">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="regNeedsApproval(regInfo)">
                        <a class="p-2" (click)="approveOrDenyReg(regInfo, true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyReg(regInfo, false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Gender</td><td>{{ dogGender }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100"><input type="text" class="form-control form-control-sm" formControlName="sex">
                      <div *ngIf="!editNeedsApproval('sex') && editMode === EditModes.editable">
                        <a class="p-2" (click)="submitField('sex',_dog?.sex)">Submit</a>
                      </div>
                      <div *ngIf="editNeedsApproval('sex')">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="editNeedsApproval('sex')">
                        <a class="p-2" (click)="approveOrDenyField('sex', true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyField('sex', false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Date of Birth</td><td>{{ dogDOB }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100">
                      <input type="text" class="form-control form-control-sm" formControlName="date_of_birth">
                      <div *ngIf="!editNeedsApproval('date_of_birth') && editMode === EditModes.editable">
                        <a class="p-2" (click)="submitField('date_of_birth',_dog?.date_of_birth)">Submit</a>
                      </div>
                      <div *ngIf="editNeedsApproval('date_of_birth')">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="editNeedsApproval('date_of_birth')">
                        <a class="p-2" (click)="approveOrDenyField('date_of_birth', true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyField('date_of_birth', false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Date of Death</td><td>{{ dogDOD }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100"><input type="text" class="form-control form-control-sm" formControlName="date_of_death">
                      <div *ngIf="!editNeedsApproval('date_of_death') && editMode === EditModes.editable">
                        <a class="p-2" (click)="submitField('date_of_death',_dog?.date_of_death)">Submit</a>
                      </div>
                      <div *ngIf="editNeedsApproval('date_of_death')">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="editNeedsApproval('date_of_death')">
                        <a class="p-2" (click)="approveOrDenyField('date_of_death', true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyField('date_of_death', false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Coat Color</td><td>{{ dogCoatColor }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
              <!--    <tr>
                    <td>Rom Points</td><td>{{ getRomPoints() }}</td>
                  </tr>
              -->
                  <tr>
                    <td>ALC3</td><td>{{ getALC3() }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                  <tr>
                    <td>ALC4</td><td>{{ getALC4() }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                  <tr *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval">
                    <td>Sire Dog Id</td><td>{{ _dog?.sire_id}}</td>
                    <td class="d-inline-flex w-100"><input type="text" class="form-control form-control-sm" formControlName="sire_id">
                      <div *ngIf="!editNeedsApproval('sire_id') && editMode === EditModes.editable">
                        <a class="p-2" (click)="submitField('sire_id',_dog?.sire_id)">Submit</a>
                      </div>
                      <div *ngIf="editNeedsApproval('sire_id')">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="editNeedsApproval('sire_id')">
                        <a class="p-2" (click)="approveOrDenyField('sire_id', true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyField('sire_id', false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Sire Registered Name</td><td>{{ getSireInfo() }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                  <tr>
                    <td>Sire Registry ID</td><td>{{ _dog?.sireInfo?.registry_id }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                  <tr *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval">
                    <td>Dam Dog Id</td><td>{{ _dog?.dam_id}}</td>
                    <td class="d-inline-flex w-100"><input type="text" class="form-control form-control-sm" formControlName="dam_id">
                      <div *ngIf="!editNeedsApproval('dam_id') && editMode === EditModes.editable">
                        <a class="p-2" (click)="submitField('dam_id',_dog?.dam_id)">Submit</a>
                      </div>
                      <div *ngIf="editNeedsApproval('dam_id')">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="editNeedsApproval('dam_id')">
                        <a class="p-2" (click)="approveOrDenyField('dam_id', true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyField('dam_id', false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Dam Registered Name</td><td>{{ getDamInfo() }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                  <tr>
                    <td>Dam Registry ID</td><td>{{ _dog?.damInfo?.registry_id }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                  <tr>
                    <td>Breeder Name</td><td>{{ getBreederInfo() }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100"><input type="text" class="form-control form-control-sm" formControlName="breeder_name">
                      <div *ngIf="!editNeedsApproval('breeder_name') && editMode === EditModes.editable">
                        <a class="p-2" (click)="submitField('breeder_name',_dog?.breeder?.breeder_name)">Submit</a>
                      </div>
                      <div *ngIf="editNeedsApproval('breeder_name')">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="editNeedsApproval('breeder_name')">
                        <a class="p-2" (click)="approveOrDenyField('breeder_name', true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyField('breeder_name', false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Owner Name</td><td>{{ _dog?.owner?.owner_name }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval" class="d-inline-flex w-100"><input type="text" class="form-control form-control-sm" formControlName="owner_name">
                      <div *ngIf="!editNeedsApproval('owner_name') && editMode === EditModes.editable">
                        <a class="p-2" (click)="submitField('owner_name',_dog?.owner?.owner_name)">Submit</a>
                      </div>
                      <div *ngIf="editNeedsApproval('owner_name')">
                        <p>Waiting for Approval</p>
                      </div>
                    </td>
                    <td *ngIf="isSuperAdmin">
                      <div *ngIf="editNeedsApproval('owner_name')">
                        <a class="p-2" (click)="approveOrDenyField('owner_name', true)">Approve?</a>
                        <a class="p-2" (click)="approveOrDenyField('owner_name', false)">Reject?</a>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="notesCount > 0">
                    <td [rowSpan]="notesCount + 1">Notes</td><td></td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                  <tr *ngFor="let note of _dog?.notes">
                    <td>{{ note.notes }}</td>
                    <td *ngIf="editMode === EditModes.editable || editMode === EditModes.waitingForApproval"></td>
                    <td *ngIf="isSuperAdmin"></td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
        <div  *ngIf="!summary">
          <app-data-display></app-data-display>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
