export interface OpensRow {
  date: string;
  formCount: number;
}
export interface FormMetrics {
  opens: OpensRow[];
}

export interface DogForm {
  id?: number;
  committeeId?: number;
  formData?: any;
  submitDate: string;
  senderWaId?: number;
  senderEmail?: string;
  senderName?: string;
  assignedTo?: string;
  assignedId?: number;
  currentState?: string;
  toState?: string;
  responseMessage?: string;
}
export interface FormList {
  id?: number;
  committeeId?: number;
  submitDate?: string;
}

export enum EditModes {
  notEditable,
  editable,
  waitingForApproval,
  done
}

