import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Dog, DogImage, DogPedigree, DogRegistration, PendingChanges } from '../models/dog.model';
import { DateTime } from 'luxon';
import { Token } from '@angular/compiler';
import { TokenStorageService } from '../services/token-storage.service';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, take, takeUntil } from 'rxjs';
import { DogService } from '../services/dog.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EditModes } from '../models/forms.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  isPAL = false;
  _dog: Dog | undefined;
  notesCount = 0;
  registryCount = 0;
  @Input() summary: boolean = false;
  @Input() editMode = EditModes.notEditable;
  isSuperAdmin = false;
  profileEditForm: FormGroup;
  _providedDOB: string | undefined;
  @Input('providedDOB')
  set in2(val: string | undefined) {
    this._providedDOB = val;
    this.profileEditForm.controls['date_of_birth'].setValue(this._providedDOB ? this._providedDOB : '');
    this.profileEditForm.controls['date_of_birth'].updateValueAndValidity();
  }
  _providedDOD: string | undefined;
  @Input('providedDOD')
  set in3(val: string | undefined) {
    this._providedDOD = val;
    this.profileEditForm.controls['date_of_death'].setValue(this._providedDOD ? this._providedDOD : '');
    this.profileEditForm.controls['date_of_death'].updateValueAndValidity();
  }

  @Input('dog')
  set in(val: Dog) {
    this._dog = val;
    this.dogCoatColor = this._dog?.coat_color?.coat_color_description ?? 'Unknown';
    const datenumeric = this._dog?.date_of_birth ?? '1/1/1970';
    const dateDate = DateTime.fromISO(datenumeric);
    this.dogDOB = dateDate.toUTC().toISODate() ?? 'Unknown';
    const datenumeric2 = this._dog?.date_of_death ?? '';
    if (datenumeric2 !== '') {
      const dateDate = DateTime.fromISO(datenumeric2);
      this.dogDOD = dateDate.toUTC().toISODate() ?? '';
    }
    this.dogGender = this._dog?.sex == 'M' ? 'Male' : 'Female' ?? 'Unknown';
    this._dog?.registry_info.forEach((regInfo) => {
      if ((regInfo.registry_organization === 'AKC') && (regInfo.registry_id?.startsWith('PAL'))) {
        this.isPAL = true;
      }
    });
    this.registryCount = ((this._dog.registry_info != null) && (this._dog.registry_info?.length > 0)) ? this._dog.registry_info?.length : 0
    this.notesCount = ((this._dog.notes != null) && (this._dog.notes?.length > 0)) ? this._dog.notes?.length : 0;
    let dogId = this._dog?.dog_id;
    if (dogId) {
      this.dogService.getPendingChanges(dogId)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.pendingChanges = res.changes;
            this.updateFormFields();
          });
        });
    }
  }
  @Input() pedigree: DogPedigree = {acl3: 0, acl4: 0, pedigree: {}};
  @Input() pendingChanges: PendingChanges[] = [];
  hasPictures = false;
  dogPictures: DogImage[] = [];
  dogCoatColor = 'Unknown';
  dogDOB = 'Unknown';
  dogDOD = '';
  dogGender = 'Unknown';
  @Output() tabSelected = new EventEmitter();

  constructor(private tokenService: TokenStorageService,
              private fb: FormBuilder,
              private ngZone: NgZone,
              private dogService: DogService,
              private breadcrumbs: BreadcrumbsService,
              private router: Router) {
    this._dog = { registry_info: [] }
    this.profileEditForm = this.fb.group({
      call_name: [{value: '', disabled: true}],
      sex: [{value: '', disabled: true}],
      date_of_birth: [{value: '', disabled: true}],
      date_of_death: [{value: '', disabled: true}],
      breeder_name: [{value: '', disabled: true}],
      owner_name: [{value: '', disabled: true}],
      sire_id: [{value: '', disabled: true}],
      dam_id: [{value: '', disabled: true}]
    });
  }

  ngOnInit(): void {
    const tabEl = document.querySelector('a[data-bs-target="#v-pills-profile"]')
    tabEl?.addEventListener('shown.bs.tab', event => {
      this.tabSelected.emit('Profile');
    });
    this.isSuperAdmin = this.tokenService.userHasRole('superAdmin');

  }
  getALC3() {
    if (this.tokenService.isUserLoggedIn()) {
      if (this.pedigree.acl3) {
        return (this.pedigree.acl3 * 100).toFixed(2);
      } else {
        return "Unknown";
      }
    } else {
      return "Members Only";
    }
  }

  getALC4() {
    if (this.tokenService.isUserLoggedIn()) {
      if (this.pedigree.acl4) {
        return (this.pedigree.acl4 * 100).toFixed(2);
      } else {
        return "Unknown";
      }
    } else {
      return "Members Only";
    }
  }

  getRomPoints() {
    if (this.tokenService.isUserLoggedIn()) {
      if (this._dog?.rom_points) {
        return this._dog.rom_points;
      } else {
        return "None";
      }
    } else {
      return "Members Only";
    }
  }
  gotoSireDog() {
    if (this._dog?.sireInfo?.dog_id) {
      const dogId = this._dog?.sireInfo?.dog_id;
      const dogName = this._dog?.sireInfo?.registered_name;
      if (dogId > 0 && dogName) {
        this.breadcrumbs.pushDog(dogId, dogName);
        this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
        this.router.navigateByUrl('/search');
      }
    }
  }
  gotoDamDog() {
    if (this._dog?.damInfo?.dog_id) {
      const dogId = this._dog?.damInfo?.dog_id;
      const dogName = this._dog?.damInfo?.registered_name;
      if (dogId > 0 && dogName) {
        this.breadcrumbs.pushDog(dogId, dogName);
        this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
        this.router.navigateByUrl('/search');
      }
    }
  }

  getSireInfo() {
    if ((this._dog?.sire_id == null) || (this._dog?.sire_id === 0)) {
      if (this.isPAL) {
        return 'Rescue Dog - Sire Not Available';
      } else {
        return '';
      }
    } else {
      return this._dog?.sireInfo?.registered_name;
    }
  }
  getDamInfo() {
    if ((this._dog?.dam_id == null) || (this._dog?.dam_id === 0)) {
      if (this.isPAL) {
        return 'Rescue Dog - Dam Not Available';
      } else {
        return '';
      }
    } else {
      return this._dog?.damInfo?.registered_name;
    }
  }

  getBreederInfo() {
    if (this._dog?.hasOwnProperty('breeder')) {
      if (this._dog?.breeder?.breeder_name === '') {
        if (this.isPAL) {
          return 'Rescue Dog - Breeder Not Provided';
        } else {
          return '';
        }
      } else {
        return this._dog?.breeder?.breeder_name;
      }
    } else {
      if (this.isPAL) {
        return 'Rescue Dog - Breeder Not Provided';
      } else {
        return '';
      }
    }
  }

  editNeedsApproval(fieldName: string) {
    return this.pendingChanges.filter((aChange) => {
      return aChange.columnName === fieldName;
    }).length > 0;
  }
  submitField(fieldName: string, curValue: any) {
    // get the edited value
    let tableName = 'dog';
    const upValue = this.profileEditForm.get(fieldName)?.value;
    // handle sex values
    if (fieldName === 'sex') {
      if (upValue) {
        if ((upValue.toLowerCase() !== 'm') && (upValue.toLowerCase() !== 'f')) {
          alert('Please enter a valid value - M or F');
          return;
        }
      } else {
        alert('Please enter a valid value - M or F');
        return;
      }
    }
    if (fieldName === 'breeder_name') {
      tableName = 'breeder';
    }
    if (fieldName === 'owner_name') {
      tableName = 'owner';
    }
    const dogId = this._dog?.dog_id;
    if (upValue && dogId) {
      this.dogService.postPendingChange({dog_id: dogId, recordIdName: 'dog_id',
      recordIdVal: dogId, tableName: tableName, columnName: fieldName,
      valueChange: {newValue: upValue, oldValue: curValue}})
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.dogService.getPendingChanges(dogId)
              .subscribe((res) => {
                this.ngZone.run(() => {
                  this.pendingChanges = res.changes;
                  this.updateFormFields();
                });
              });
          });
        },
          error => {
          console.log('error with pending change');
          });
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  approveOrDenyField(fieldName: string, isApproved: boolean) {
    const aPending = this.pendingChanges.filter((aChange) => {
      return aChange.columnName === fieldName;
    });
    if (aPending.length === 1) {
      const toApprove = aPending[0];
      toApprove.state = isApproved ? 'approved' : 'denied';
      const dogId = this._dog?.dog_id;
      if (dogId) {
        this.dogService.approvePendingChange(dogId, toApprove.id, toApprove)
          .subscribe((res) => {
            this.ngZone.run(() => {
              this.dogService.getPendingChanges(dogId)
                .subscribe((res) => {
                  this.ngZone.run(() => {
                    this.pendingChanges = res.changes;
                    this.updateFormFields();
                  });
                });
            });
          });
      }
    }
  }


  private updateFormFields() {
    this.pendingChanges.forEach(aChange => {
      const theField = this.profileEditForm.get(aChange.columnName);
      if (theField) {
        const parsedValue = JSON.parse(aChange.valueChange);
        theField.setValue(parsedValue.newValue);
        theField.updateValueAndValidity();
      }
    });
    for (let controlsKey in this.profileEditForm.controls) {
      const theField = this.profileEditForm.get(controlsKey);
      if (theField) {
        if (this.editMode === EditModes.editable) {
          theField.enable()
        } else {
          theField.disable()
        }
      }
    }
  }

  regNeedsApproval(regInfo: DogRegistration) {
    return false;
  }

  editReg(regInfo: DogRegistration) {

  }

  approveOrDenyReg(regInfo: DogRegistration, approve: boolean) {

  }

  sireNeedsApproval() {
    return false;
  }

  editSire() {

  }

  approveOrDenySire(approve: boolean) {

  }

  damNeedsApproval() {
    return false;
  }

  editDam() {

  }

  approveOrDenyDam(approve: boolean) {

  }

  protected readonly EditModes = EditModes;
}
