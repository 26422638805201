import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from '../summary/summary.component';
import { AppModule } from '../app.module';
import { ProfileComponent } from '../profile/profile.component';
import { PedigreeComponent } from '../pedigree/pedigree.component';
import { HealthComponent } from '../health/health.component';
import { AwardsComponent } from '../awards/awards.component';
import { DataDisplayComponent } from '../data-display/data-display.component';
import { NgxPopperModule } from 'ngx-popper';
import { ModalComponent } from '../modal/modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    SummaryComponent,
    ProfileComponent,
    PedigreeComponent,
    HealthComponent,
    AwardsComponent,
    DataDisplayComponent,
    ModalComponent
  ],
    imports: [
        CommonModule,
        NgxPopperModule,
        NgxPopperModule.forRoot(),
        ReactiveFormsModule,
        MatInputModule
    ],
  exports: [
    SummaryComponent,
    ProfileComponent,
    PedigreeComponent,
    HealthComponent,
    AwardsComponent,
    DataDisplayComponent,
    ModalComponent
  ]
})
export class SharedModule { }
