import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HealthCategoriesResponse } from '../models/dog.model';

@Component({
  selector: 'app-query-modal',
  templateUrl: './query-modal-health-condition.component.html',
  styleUrls: ['./query-modal-health-condition.component.scss']
})
export class QueryModalHealthConditionComponent implements OnInit, AfterViewInit {
  @Input() prompt?: string;
  @Input()healthCategories: HealthCategoriesResponse = {};
  healthCategoryNames?: (string | undefined)[] | undefined = [];
  healthCategoryOptionNames: (string | undefined)[] | undefined = [];
  healthEditForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<QueryModalHealthConditionComponent>
  ) {
    this.healthEditForm = this.fb.group({
      healthCategory: [''],
      healthCategoryOption: [''],
      dateOfDeath: [''],
      causeOfDeathCategory: [''],
      causeOfDeathCategoryOption: ['']
    });

  }

  onSubmit(queryName: string) {
    const selectedCondition = this.healthEditForm.controls['healthCategory'].value;
    this.dialogRef.close({category: selectedCondition, cause: queryName});
  }
  ngOnInit(): void {
    this.healthCategoryNames = this.healthCategories.health?.map((acat) => acat.title);
  }

  updateHealthOptions() {
    const selectedCondition = this.healthEditForm.controls['healthCategory'].value;
    const conditionArray = this.healthCategories.health?.filter((acat) => acat.title === selectedCondition);
    if (conditionArray?.length === 1) {
      const condition = conditionArray[0];
      this.healthCategoryOptionNames = condition.options?.map((acond) => acond.title);
    }
  }

  ngAfterViewInit(): void {
    this.healthCategoryNames = this.healthCategories.health?.map((acat) => acat.title);
  }

}
