import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  isLoggedIn: boolean = false;
  private isLoggedIn$: BehaviorSubject<boolean>;
  hasToken: boolean = false;
  private hasToken$: BehaviorSubject<boolean>;
  private redirectUrl: string = '/';
  private isAdmin: boolean = false;
  private roles: string[] = [];

  constructor() {
    this.isLoggedIn$ = new BehaviorSubject<boolean>(false);
    this.hasToken$ = new BehaviorSubject<boolean>(false);
  }

  public getLoggedInNotification(): BehaviorSubject<boolean> {
    return this.isLoggedIn$;
  }

  public getHasTokenNotification(): BehaviorSubject<boolean> {
    return this.hasToken$;
  }
  signOut(): void {
    window.sessionStorage.clear();
    this.isLoggedIn = false;
    this.isLoggedIn$.next(this.isLoggedIn);
    this.hasToken = false;
    this.hasToken$.next(this.hasToken);
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    this.hasToken = (token !== null) && (token.length > 0);
    if (this.hasToken) {
      const tokenInfo = this.getDecodedAccessToken(token); // decode token
      if (tokenInfo) {
        this.isAdmin = tokenInfo.isAdmin;
        this.roles = tokenInfo.roles;
      }
    }
    this.hasToken$.next(this.hasToken);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    this.isLoggedIn = !!user.waId;
    this.isLoggedIn$.next(this.isLoggedIn);
  }

  public isUserLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      const resp = JSON.parse(user);
      this.isLoggedIn = resp.hasOwnProperty('waId');
      this.isLoggedIn$.next(this.isLoggedIn);
      return resp;
    }

    this.isLoggedIn$.next(false);
    return {};
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }
  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  isAdminUser(): boolean {
    return this.isAdmin;
  }

  userHasRole(role: string) {
    return this.roles?.includes(role);
  }

  getDecodedAccessToken(token: string): any | null {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }

}
