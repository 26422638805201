import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const AUTH_API = environment.dbUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(email: string, password: string, service: string): Observable<any> {
    return this.http.post(AUTH_API + '/login', {
      email,
      password,
      service
    }, httpOptions);
  }
  guestLogin(): Observable<any> {
    return this.http.post(AUTH_API + '/login/guest', {}, httpOptions);
  }

  validateToken(): Observable<any> {
    return  this.http.post(AUTH_API + '/validate/token', {service: "db"}, httpOptions);
  }
  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }
}
