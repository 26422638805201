import { Injectable }       from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { TokenStorageService } from './token-storage.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private tokenService: TokenStorageService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('Url: '+ route.url);
    if (this.tokenService.isAdminUser()) {
      return true;
    }
    this.tokenService.setRedirectUrl(route.url.toString());
    // this.router.navigateByUrl('/login');
    this.router.navigate(["login"],{ queryParams: { retUrl: route.url } });
    return false;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.tokenService.isAdminUser();
  }
}
