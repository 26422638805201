<div class="content text-center">
  <div class="who_we_are">
    <div class="container">
      <div class="grid_12">
        <header>
          <h3>About the NCA</h3>
        </header>
        <p align="left">The Newfoundland Club of America (NCA) was founded in 1930 for the advancement of the Newfoundland Dog. The NCA is the sanctioned parent breed club for the Newfoundland and is a member of the American Kennel Club. The NCA has many Regional Newfoundland clubs that create a network of support to Newfoundland dog owners. Members include breeders, owners and fanciers who are committed to protecting the Newfoundland. Regional clubs offer meetings, matches, Draft and Water Tests, NCA Regional Specialties, and educational programs. The NCA and Regional Clubs actively support canine health research and provide rescue adoption assistance for Newfoundlands through the NCA Charitable Trust</p>
        <p align="left">&nbsp;</p>
        <h5 align="left">How is the NCA organized?</h5>
        <p align="left">The NCA is the sanctioned parent breed club for the Newfoundland and is a member of the American Kennel Club. The NCA is governed by a Board of 12 Directors who are elected by the membership to serve two year terms. </p>
        <p align="left">The NCA establishes committees to carry out its work. Some examples of NCA committees are: Breeder Education, Health and Longevity, Judges Education, Working Dog and Performance and Recognition. </p>
        <p align="left">The NCA has many Regional Newfoundland clubs that create a network of support to Newfoundland dog owners. Members include breeders, owners and fanciers who are committed to protecting the Newfoundland. Regional clubs offer meetings, matches, Draft and Water Tests, NCA Regional Specialties, and educational programs.</p>
        <h5 align="left">Publications</h5>
        <p align="left">Various educational efforts of the NCA, including the publication of <em>Newf Tide </em>(an award-winning quarterly magazine), cover every aspect of Newfoundland ownership. The NCA also publishes various pamphlets on the Newfoundland such as the Newf & You, and various manuals and publications for water and draft work. The NCA also has an active online presence with a suite of websites and regular postings on social media networks.</p>
        <h5 align="left">Showcase Events</h5>
        <p align="left">National Specialties showcase the Newfoundland breed in Conformation, Obedience, and Working Abilities. Held on a yearly basis, the National travels around the country hosted by a regional club. A National has, by far, the largest entry of Newfoundland dogs, typically over 500.</p>
        <p align="left">Regional Specialities are smaller than a "National" and are hosted by regional clubs, normally on an annual or biennial basis.</p>
        <p align="left">Working events, such as Draft or Water Tests, demonstrate the Newfoundland's natural abilities as a working dog and are conducted by most regional clubs on a periodic basis.</p>
        <h5 align="left">Titles and Awards</h5>
        <p align="left">Upon completion of the necessary requirements, the NCA offers the following titles:</p>
        <ul align="left">
          <li><strong>BDD</strong>, successful completion of the Beginner Draft Dog requirements</li>
          <li><strong>DD </strong>Draft Dog, successful completion of a Draft Test</li>
          <li><strong>TDD</strong> Team Draft Dog, successful completion of a Draft Test when a team of two or more dogs are used throughout the Draft Test</li>
          <li><strong>DDX</strong>, successful completion of Draft Dog Excellent requirements</li>
          <li><strong>TDDX</strong> Team Draft Dog Excellent, successful completion of a Excellent Level Draft Test when a team of two or more dogs are used throughout the Test</li>
          <li><strong>WD</strong> Water Dog, successful completion of Junior Water Test Exercises </li>
          <li><strong>WRD</strong> Water Rescue Dog, successful completion of the Senior Water Test Exercises </li>
          <li><strong>WRDX</strong> Water Rescue Dog Excellent, successful completion of the Water Rescue Dog Excellent Exercises</li>
          <li><strong>VN</strong> <a href="https://www.thenewfoundland.org/vn.php">Versitile Newfoundland</a>, for completion of an AKC conformation championship, AKC obedience title, NCA DD (Draft Dog) and NCA WRD (Water Rescue Dog) </li>
          <li><strong>ROM</strong> <a href="https://www.thenewfoundland.org/rom.php">Register of Merit</a>, for meeting the requirement of a specific number of AKC Championship/AKC Obedience or NCA Working titled offspring</li>
          <li><strong>TOD</strong> (Health Tested Open Database) for any dog, living or dead, for which the test results for hips, elbows, cystinuria, and heart (evaluated by a board-certified cardiologist) have been posted in an openly accessible database. </li>
          <li><strong>TOD-D</strong> will be awarded to any dog that meets the health submission requirements for TOD AND has submitted its DNA to the AKC DNA Repository.</li>
          <li><strong>WA</strong> the <a href="https://www.thenewfoundland.org/wa.php">Working Achievement Award</a> is given to honor those Newfoundland/handler teams that excel in dog sports that highlight the intelligence and versatility of our breed, to recognize the unique bond between a handler and their dog, and to promote participation at a high level of excellence in performance events.</li>
        </ul>
        <p align="left">&nbsp;</p>
        <p align="left">Annual Awards may be given the following:</p>
        <ul align="left">
          <li>The Top Winning Newfoundland Dog and Bitch in Conformation, along with the Top Twenty Conformation Newfoundlands</li>
          <li>The Top Producing Newfoundland Dog and Bitch</li>
          <li>The Top Obedience Newfoundland along with the Top Ten Obedience Newfoundlands</li>
          <li>The Top Junior Handler</li>
          <li>The Oldest Living Newfoundland along with all Newfoundlands reaching the age of 14</li>
          <li><a href="https://www.thenewfoundland.org/goodsport.html">Sam Butler Good Sportsmanship Award</a></li>
          <li><a href="https://www.thenewfoundland.org/moy.html">NCA Member of the Year</a></li>
          <li><a href="https://www.thenewfoundland.org/kurth.html">Isabel Kurth Award for Service to the NCA and the breed</a></li>
        </ul>
        <p align="left">&nbsp;</p>
        <p align="left">The NCA also recognizes <a href="https://www.newfoundlandbreeder.org/bodistlist.php">Breeders of Distinction</a>, both active and emiritus, for their work in preserving the breed.</p>
        <p align="left">&nbsp;</p>
        <h5 align="left">Membership Process</h5>
        <p align="left">New members can apply online via the <a href="https://members.ncanewfs.org">Membership Portal</a>.  The applicant is responsible for obtaining two sponsors’ signatures and providing them with the sponsors’ form to complete and send to the <a href="mailto:membership@ncadogs.org">Membership Chair</a>.  It is highly recommended that the applicant ensure that the two sponsors have been NCA members for three or more years, are not from the same household and have known the applicant for at least a year.  It is suggested that the breeders of the applicant’s dogs, local Newfoundland owners and local regional Newfoundland clubs be utilized to make the necessary contacts.  Contacts can also be made at local dog shows and possibly at all-breed kennel clubs.</p>
        <h5 align="left">History of the NCA</h5>
        <p align="left">The Newfoundland Club of America maintains an extensive collection with the archive at the American Kennel Club. There is an <a href="https://www.akc.org/about/archive/guide-collections/nca-collection/">exhibit and guide to the collection online.</a> Research Inquiries should be directed to the <a href="http://akc151.libraryhost.com/repositories/2/resources/54">AKC Archives.</a> </p>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="why_choose_us">
    <div class="container">
      <div class="row">
        <header>
          <h3>Governing Documents</h3>
        </header>
      </div>
      <div class="col-md-4 grid_4">
        <div>
          <span>01</span>
          <p><a href="assets/pdfs/NCAByLaws.pdf">By-Laws</a></p>
        </div>
      </div>
      <div class="col-md-4 grid_4">
        <div>
          <span>02</span>
          <p><a href="assets/pdfs/NCAConstitution.pdf">Constitution</a></p>

        </div>
      </div>
      <div class="col-md-4 grid_4">
        <div>
          <span>03</span>
          <p><a href="assets/pdfs/NCAEthicsGuide.pdf">Code of Ethics</a></p>

        </div>
      </div>
    </div>
  </div>
</div>
