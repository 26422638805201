<div class="content text-center">
  <div>
    <div class="container-fluid" style="--bs-gutter-x: 0;">
      <div *ngIf="!summary" class="grid_12">
        <header>
          <h4>Registered Name: {{ _dog?.registered_name }}</h4>
        </header>
        <div class="row pictures-row">
          <div *ngIf="image" class="col-md-10">
            <img class="img-responsive img-thumbnail" src="{{image.src}}" alt="Dog Image">
          </div>
          <div *ngIf="!image" class="col-md-10 text-center mx-auto">
            <p class="p-3" >Pictures feature coming soon.</p>
          </div>
        </div>
        <button class="btn btn-secondary btn-spacing" (click)="exportPedigree()">Export Printable Pedigree</button>
        <button class="btn btn-secondary btn-spacing ms-2" (click)="showPubReport()">Publication Report</button>
        <div class="pedigree-view table-responsive text-nowrap table-fixed" id="pedigree-view-2">
          <table class="table table-hover text-wrap" style="width: 1500px;table-layout: fixed;cursor: pointer;">
            <tr>
              <td rowspan="16"><p [class]="getDogGenderClass()" #dogName [innerHTML]="dogRegNameFull"></p></td>
              <td rowspan="16"></td>
              <td rowspan="8"><p class="peddog dog" (click)="gotoDog(sireId, dogSireName)" #Sire [innerHTML]="dogSireNameFull"></p></td>
              <td rowspan="16"></td>
              <td rowspan="4"><p class="peddog dog" (click)="gotoDog(sireSireId, dogSireSireName)" #SireSire [innerHTML]="dogSireSireNameFull"></p></td>
              <td rowspan="16"></td>
              <td rowspan="2"><p class="peddog dog" (click)="gotoDog(sireSireSireId, dogSireSireSireName)" #SireSireSire [innerHTML]="dogSireSireSireNameFull"></p></td>
              <td rowspan="16"></td>
              <td><p class="peddog dog" (click)="gotoDog(sireSireSireSireId, dogSireSireSireSireName)" #SireSireSireSire [innerHTML]="dogSireSireSireSireName"></p></td>
            </tr>
            <tr>
              <td><p class="peddog bitch" (click)="gotoDog(sireSireSireDamId, dogSireSireSireDamName)" #SireSireSireDam [innerHTML]="dogSireSireSireDamName"></p></td>
            </tr>
            <tr>
              <td rowspan="2"><p class="peddog bitch" (click)="gotoDog(sireSireDamId, dogSireSireDamName)" #SireSireDam [innerHTML]="dogSireSireDamNameFull"></p></td>
              <td><p class="peddog dog" (click)="gotoDog(sireSireDamSireId, dogSireSireDamSireName)" #SireSireDamSire [innerHTML]="dogSireSireDamSireName"></p></td>
            </tr>
            <tr>
              <td><p class="peddog bitch" (click)="gotoDog(sireSireDamDamId, dogSireSireDamDamName)" #SireSireDamDam [innerHTML]="dogSireSireDamDamName"></p></td>
            </tr>
            <tr>
              <td rowspan="4"><p class="peddog bitch" (click)="gotoDog(sireDamId, dogSireDamName)" #SireDam [innerHTML]="dogSireDamNameFull"></p></td>
              <td rowspan="2"><p class="peddog dog" (click)="gotoDog(sireDamSireId, dogSireDamSireName)" #SireDamSire [innerHTML]="dogSireDamSireNameFull"></p></td>
              <td><p class="peddog dog" (click)="gotoDog(sireDamSireSireId, dogSireDamSireSireName)" #SireDamSireSire [innerHTML]="dogSireDamSireSireName"></p></td>
            </tr>
            <tr>
              <td><p class="peddog bitch" (click)="gotoDog(sireDamSireDamId, dogSireDamSireDamName)" #SireDamSireDam [innerHTML]="dogSireDamSireDamName"></p></td>
            </tr>
            <tr>
              <td rowspan="2"><p class="peddog bitch" (click)="gotoDog(sireDamDamId, dogSireDamDamName)" #SireDamDam [innerHTML]="dogSireDamDamNameFull"></p></td>
              <td><p class="peddog dog" (click)="gotoDog(sireDamDamSireId, dogSireDamDamSireName)" #SireDamDamSire [innerHTML]="dogSireDamDamSireName"></p></td>
            </tr>
            <tr>
              <td><p class="peddog bitch" (click)="gotoDog(sireDamDamDamId, dogSireDamDamDamName)" #SireDamDamDam [innerHTML]="dogSireDamDamDamName"></p></td>
            </tr>

            <!-- Dam side -->
            <tr>
              <td rowspan="8"><p class="peddog bitch" (click)="gotoDog(damId, dogDamName)" #Dam [innerHTML]="dogDamNameFull"></p></td>
              <td rowspan="4"><p class="peddog dog" (click)="gotoDog(damSireId, dogDamSireName)" #DamSire [innerHTML]="dogDamSireNameFull"></p></td>
              <td rowspan="2"><p class="peddog dog" (click)="gotoDog(damSireSireId, dogDamSireSireName)" #DamSireSire [innerHTML]="dogDamSireSireNameFull"></p></td>
              <td><p class="peddog dog" (click)="gotoDog(damSireSireSireId, dogDamSireSireSireName)" #DamSireSireSire [innerHTML]="dogDamSireSireSireName"></p></td>
            </tr>
            <tr>
              <td><p class="peddog bitch" (click)="gotoDog(damSireSireDamId, dogDamSireSireDamName)" #DamSireSireDam [innerHTML]="dogDamSireSireDamName"></p></td>
            </tr>
            <tr>
              <td rowspan="2"><p class="peddog bitch" (click)="gotoDog(damSireDamId, dogDamSireDamName)" #DamSireDam [innerHTML]="dogDamSireDamNameFull"></p></td>
               <td><p class="peddog dog" (click)="gotoDog(damSireDamSireId, dogDamSireDamSireName)" #DamSireDamSire [innerHTML]="dogDamSireDamSireName"></p></td>
            </tr>
            <tr>
              <td><p class="peddog bitch" (click)="gotoDog(damSireDamDamId, dogDamSireDamDamName)" #DamSireDamDam [innerHTML]="dogDamSireDamDamName"></p></td>
            </tr>
            <tr>
              <td rowspan="4"><p class="peddog bitch" (click)="gotoDog(damDamId, dogDamDamName)" #DamDam [innerHTML]="dogDamDamNameFull"></p></td>
              <td rowspan="2"><p class="peddog dog" (click)="gotoDog(damDamSireId, dogDamDamSireName)" #DamDamSire [innerHTML]="dogDamDamSireNameFull"></p></td>
              <td><p class="peddog dog" (click)="gotoDog(damDamSireSireId, dogDamDamSireSireName)" #DamDamSireSire [innerHTML]="dogDamDamSireSireName"></p></td>
            </tr>
            <tr>
              <td><p class="peddog bitch" (click)="gotoDog(damDamSireDamId, dogDamDamSireDamName)" #DamDamSireDam [innerHTML]="dogDamDamSireDamName"></p></td>
            </tr>
            <tr>
              <td rowspan="2"><p class="peddog bitch" (click)="gotoDog(damDamDamId, dogDamDamDamName)" #DamDamDam [innerHTML]="dogDamDamDamNameFull"></p></td>
              <td><p class="peddog dog" (click)="gotoDog(damDamDamSireId, dogDamDamDamSireName)" #DamDamDamSire [innerHTML]="dogDamDamDamSireName"></p></td>
            </tr>
            <tr>
              <td><p class="peddog bitch" (click)="gotoDog(damDamDamDamId, dogDamDamDamDamName)" #DamDamDamDam [innerHTML]="dogDamDamDamDamName"></p></td>
            </tr>
          </table>
        </div>

      </div>

      <div *ngIf="summary">
        <table class="table text-wrap">
          <tr>
            <th style="width: 20%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 20%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 20%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 20%!important;"></th>
          </tr>
          <tr>
            <td colspan="8"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogSireSireSireNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="8"></td>
            <td class="pedLeftBorder"></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogSireSireNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogSireSireDamNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogSireNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogSireDamSireNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogSireDamNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="5"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogSireDamDamNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="6"></td>
          </tr>
          <tr>
            <td rowspan="2" style="background-color: white;"><p [class]="getDogGenderClass()" [innerHTML]="dogRegNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="7"></td>
          </tr>
          <tr>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td colspan="7"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="5"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogDamSireSireNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogDamSireNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogDamSireDamNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogDamNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogDamDamSireNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogDamDamNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="6"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="8"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogDamDamDamNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="9"></td>
          </tr>
        </table>
      </div>
      <div class="pedigree-export-view d-none" #pedigreeExportView id="pedigreeExport">
        <h4 class="text-start">Registered Name: {{ _dog?.registered_name }}</h4>
        <ul class="text-start" style="list-style-type:none;padding: 0; margin: 0;">
          <li>Owner: {{ _dog?.owner?.owner_name }}</li>
          <li>Breeder: {{ _dog?.breeder?.breeder_name }}</li>
          <li>DOB: {{ dogDOB }}</li>
          <li>Sex: {{ _dog?.sex }}</li>
          <li>Color: {{ dogCoatColor }}</li>
          <li *ngFor="let regInfo of _dog?.registry_info">{{ regInfo.registry_id }}</li>
        </ul>
        <table class="table text-wrap" style="width: 1200px;">
          <tr>
            <th style="width: 20%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 20%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 20%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 3%!important;"></th>
            <th style="width: 20%!important;"></th>
          </tr>
          <tr>
            <td colspan="8"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogSireSireSireNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="8"></td>
            <td class="pedLeftBorder"></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogSireSireNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogSireSireDamNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogSireNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogSireDamSireNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogSireDamNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="5"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogSireDamDamNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="6"></td>
          </tr>
          <tr>
            <td rowspan="2" style="background-color: white;"><p [class]="getDogGenderClass()" [innerHTML]="dogRegNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="7"></td>
          </tr>
          <tr>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td colspan="7"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="5"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogDamSireSireNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogDamSireNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogDamSireDamNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogDamNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog dog" [innerHTML]="dogDamDamSireNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder"></td>
            <td colspan="2"></td>
            <td class="pedLeftBorder"></td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogDamDamNameFull"></p></td>
            <td class="pedBotBorder"></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="6"></td>
            <td></td>
            <td class="pedLeftBorder"></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="8"></td>
            <td class="pedLeftBorder pedBotBorder"></td>
            <td rowspan="2" style="background-color: white;"><p class="peddog bitch" [innerHTML]="dogDamDamDamNameFull"></p></td>
          </tr>
          <tr>
            <td colspan="9"></td>
          </tr>
        </table>
        <h6 class="text-start">Compiled on {{ todaysDate }}.  Copyright 1997-2023 Newfoundland Club of America</h6>

      </div>
      <div class="clearfix"></div>
      <app-modal id="modal-pub">
        <div class="modal-header">
          <h5 class="modal-title">Publication Report</h5>
          <div class="ms-auto" [popper]="tooltipcontent"
               [popperTrigger]="'none'"
               [popperPlacement]="'bottom'"
               [popperApplyClass]="'bg-white'"
          >
            <button class="btn btn-secondary" (click)="copy()">Copy Report</button>
          </div>
          <popper-content #tooltipcontent>
            <div style="text-align: center;background-color:white;"><p class="text-success mb-0">Copied!</p></div>
          </popper-content>
          <popper-content #failedtooltipcontent>
            <div style="text-align: center;background-color:white;"><p class="text-success mb-0">Unable to copy!</p></div>
          </popper-content>


          <button type="button" class="btn-close" (click)="closePubReport()"></button>
        </div>
        <div class="modal-body">
          <div id="pubSection" class="pedText">
            <b>{{ dogRegName }} ({{ dogCallName }})</b>
            <br><b>Owner:&nbsp;</b>{{ _dog?.owner?.owner_name }}
            <br><b>Breeder:&nbsp;</b>{{ _dog?.breeder?.breeder_name }}
            <br>{{ dogDOBPub }}
            <pre>	</pre>{{ _dog?.sex }}<pre>	</pre>{{ _dog?.coat_color?.coat_color_abbr }}<pre>	</pre>
            <span *ngFor="let regInfo of _dog?.registry_info">
              {{ regInfo.registry_id }}<pre> </pre>
            </span>
            <br>
            <span *ngFor="let dogHealth of dogRegHealth">{{ dogHealth }}<pre>	</pre></span>
            <br>
            {{ dogSireSireSireName }}<pre>	</pre><span *ngFor="let dogHealth of dogSireSireSireHealth">{{ dogHealth }}<pre>	</pre></span><!-- Dad-Dad-Dad -->
            <br>
            {{ dogSireSireName }}<pre>	</pre><span *ngFor="let dogHealth of dogSireSireHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Dad-Dad -->
            <br>
            {{ dogSireSireDamName }}<pre>	</pre><span *ngFor="let dogHealth of dogSireSireDamHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Dad-Dad-Mom -->
            <br>
            {{ dogSireName }}<pre>	</pre><span *ngFor="let dogHealth of dogSireHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Dad -->
            <br>
            {{ dogSireDamSireName }}<pre>	</pre><span *ngFor="let dogHealth of dogSireDamSireHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Dad-Mom-Dad -->
            <br>
            {{ dogSireDamName }}<pre>	</pre><span *ngFor="let dogHealth of dogSireDamHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Dad-Mom -->
            <br>
            {{ dogSireDamDamName }}<pre>	</pre><span *ngFor="let dogHealth of dogSireDamDamHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Dad-Mom-Mom -->
            <br>
            {{ dogRegName }}<pre>	</pre><span *ngFor="let dogHealth of dogRegHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Main -->
            <br>
            {{ dogDamSireSireName }}<pre>	</pre><span *ngFor="let dogHealth of dogDamSireSireHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Mom-Dad-Dad -->
            <br>
            {{ dogDamSireName}}<pre>	</pre><span *ngFor="let dogHealth of dogDamSireHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Mom-Dad -->
            <br>
            {{ dogDamSireDamName }}<pre>	</pre><span *ngFor="let dogHealth of dogDamSireDamHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Mom-Dad-Mom -->
            <br>
            {{ dogDamName }}<pre>	</pre><span *ngFor="let dogHealth of dogDamHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Mom -->
            <br>
            {{ dogDamDamSireName }}<pre>	</pre><span *ngFor="let dogHealth of dogDamDamSireHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Mom-Mom-Dad -->
            <br>
            {{ dogDamDamName }}<pre>	</pre><span *ngFor="let dogHealth of dogDamDamHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Mom-Mom -->
            <br>
            {{ dogDamDamDamName }}<pre>	</pre><span *ngFor="let dogHealth of dogDamDamDamHealth">{{ dogHealth }}<pre>	</pre></span> <!-- Mom-Mom-Mom -->
            <br>
            <br>
            <!-- Compiled on {{ todaysDate }}. Copyright © 1997-2023 Newfoundland Club of America Database<p></p> -->
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closePubReport();">Close</button>
        </div>

      </app-modal>
    </div>
  </div>
</div>
