import { Component } from '@angular/core';

@Component({
  selector: 'app-more-ways-to-connect',
  templateUrl: './more-ways-to-connect.component.html',
  styleUrls: ['./more-ways-to-connect.component.scss']
})
export class MoreWaysToConnectComponent {

}
