import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { TokenStorageService } from '../services/token-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  useLargeHeader = true;
  useHeader = true;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    this.router.events.subscribe((event2) =>
    {
      if (event2 instanceof NavigationEnd) {
        this.useLargeHeader = !event2.url.startsWith('/search') && !event2.url.startsWith('/form');
        this.useHeader = !event2.url.startsWith('/admin');
      }
    });
  }

  openMenu() {
    const hamburger = document.getElementById('hamburger');
    if (hamburger !== null) {
      hamburger.classList.toggle('show');
    }
  }

}
