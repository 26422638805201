<div class="container-fluid">
  <div class="row p-3 mx-auto">
    <div class="col-md-7">
      <div class="card w-100 mt-1">
        <div class="card-body">
          <div class="float-left">
            <h5 class="ml-3">Form details for form #{{ thisForm.id }}</h5>
          </div>
          <table class="table table-bordered table-sm table-tight ml-2">
            <tr>
              <td>Current state:</td><td>{{ thisForm.currentState }}</td>
            </tr>
            <tr>
              <td>Assigned to:</td><td>{{ thisForm.assignedTo }}</td>
            </tr>
            <tr>
              <td>Submit Date:</td><td>{{ submitReadableDate }}</td>
            </tr>
            <tr>
              <td>Submit Date:</td><td>{{ submitReadableDate }}</td>
            </tr>
            <tr>
              <td>Sender Name:</td><td>{{ thisForm.senderName }}</td>
            </tr>
            <tr>
              <td>Sender Email:</td><td>{{ thisForm.senderEmail }}</td>
            </tr>
            <tr>
              <td>Dog Id:</td><td>{{ theDogId }}</td>
            </tr>
            <tr>
              <td [rowSpan]="actionCount" class="fw-bold">Actions Requested:</td>
            </tr>
            <tr *ngIf="wantsToChangeCallName">
              <td>Wants to update call name</td>
            </tr>
            <tr *ngIf="wantsToChangeSireDam">
              <td>Wants to update Sire or Dam information</td>
            </tr>
            <tr *ngIf="wantsToUpdateHealthInfo">
              <td>Wants to update OFA Health information</td>
            </tr>
            <tr *ngIf="wantsToAddHealthCondition">
              <td>Wants to add a health condition</td>
            </tr>
            <tr *ngIf="wantsToChangeTitles">
              <td>Wants to correct titles information</td>
            </tr>
            <tr *ngIf="wantsToAddDeathInfo">
              <td>Wants to add cause of death information</td>
            </tr>
            <tr *ngIf="wantsToChangeOtherInfo">
              <td>Wants to update some other information</td>
            </tr>
            <tr *ngIf="wantsToAddDOB">
              <td>Wants to add date of birth</td>
            </tr>
            <tr *ngIf="wantsToAddDOD">
              <td>Wants to add date of death</td>
            </tr>
            <tr>
              <td>Correction Notes:</td><td>{{ theDogNotes }}</td>
            </tr>
          </table>
          <div class="row">
            <h5 class="ml-3">Images</h5>
          </div>
          <div class="row d-inline-flex">
            <div class="col-md-4" *ngFor="let image of imageArray">
              <a  href="{{ image.imagePath }}" download="">
                <img *ngIf="image.isViewable" src="{{ image.imagePath }}" height="200">
                <p *ngIf="!image.isViewable" class="p-3 text-center" style="border: 1px solid black; height: 200px;">This image is not viewable in your browser but can be downloaded. Click here to download</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="card w-100 mt-1">
        <div class="card-body">
          <div class="text-center d-block">
            <h5>Actions:</h5>
          </div>
          <div class="text-center d-none">
            <button class="btn btn-primary mb-3" type="button" (click)="changeState('resolvedDone')"
                    [disabled]="getStateDisable('approve')">Approve Form</button>
          </div>
          <div class="text-center d-none">
            <button class="btn btn-primary mb-3" type="button" (click)="changeState('resolvedRejected')"
                    [disabled]="getStateDisable('reject')">Reject Form</button>
          </div>
          <div class="text-center d-block mb-3">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle dropdown-toggle-split" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                      [disabled]="!isAssignable">
                Assign to:
              </button>
              <ul class="dropdown-menu">
                <li *ngFor="let member of dbAdmins">
                  <a class="dropdown-item" (click)="reassign(member)">{{member.firstName}} {{member.lastName}}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-center d-block">
            <button class="btn btn-primary mb-3" type="button" (click)="submitForApproval()"
                    [disabled]="getStateDisable('submitForApproval')">Submit Back to Admin</button>
          </div>
          <div *ngIf="(thisForm.currentState === 'pendingApproval') && (thisForm.assignedId === curUser.waId)" class="text-center d-grid">
            <label for="responseMessage">Enter text to send back to the submitter of this form:</label>
            <textarea rows="3" #responseMessage id="responseMessage"></textarea>
            <button class="btn btn-primary mb-3 mt-2" type="button" (click)="completeForm()">Complete Form</button>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10">
      <div class="overlay" *ngIf="mySpinner">
        <div class="spinner-wrapper">
          <app-admin-spinner></app-admin-spinner>
        </div>
      </div>
      <h5>Dog Record Details</h5>
      <app-summary [dog]="selectedDogInfo"
                   [member]="true"
                   [healths]="selectedDogHealth"
                   [awards]="selectedDogAwards"
                   [pedigree]="selectedDogPedigree"
                   [editMode]="editMode"
                   [formId]="formId"
                   [hasCauseOfDeath]="wantsToAddDeathInfo"
                   [hasHealthCondition]="wantsToAddHealthCondition"
                   [providedDOB]="dateOfBirth"
                   [providedDOD]="dateOfDeath"
      ></app-summary>
    </div>
  </div>
</div>
