import { Component, Input, ViewChild } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { FormMetrics } from '../../../models/forms.model';
import { Dog } from '../../../models/dog.model';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-sub-by-date',
  templateUrl: './sub-by-date.component.html',
  styleUrls: ['./sub-by-date.component.scss']
})
export class SubByDateComponent {

  _metrics: FormMetrics | undefined;
  @Input('metrics')
  set in(val: FormMetrics) {
    this._metrics = val;
    this.chartLoading = false;
    // setup the chart
    this.updateCharts();
  }
  chartLoading = true;

  chartOption:EChartsOption = {
    title: {
      text: "New Submissions By Date"
    },
    xAxis: {
      data: [],
    },
    yAxis: {
      type: 'value'
    },
    series: [{}]
  };

  dynamicSubsData: EChartsOption = {};

  updateCharts() {
    setTimeout(() =>{
      this.dynamicSubsData = this.chartOption;
      this
      .dynamicSubsData.xAxis = {data: this._metrics?.opens.map(c => c.date)};
      this
      .dynamicSubsData.series = [{
        name: 'forms',
        type: 'line',
        data: this._metrics?.opens.map(value => value.formCount)
      }];
    }, 1000);
  }
}
