<div class="content">
  <div class="who_we_are" style="padding: 1em 0;">
    <div class="container-fluid">
      <div class="overlay large-overlay" *ngIf="showDataReaderSpinner">
        <div class="spinner-wrapper">
          <app-spinner></app-spinner>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 d-flex mb-3" style="justify-content: space-between;">
          <button class="btn btn-outline-secondary btn-spacing" *ngIf="showTabs" (click)="returnToList()">Return to Search</button>
          <div [popper]="tooltipcontent"
               [popperTrigger]="'none'"
               [popperPlacement]="'bottom'"
               [popperApplyClass]="'bg-white'"
          >
            <button class="btn btn-secondary btn-spacing" (click)="getDogLink()" *ngIf="showTabs">Share Dog Link</button>
          </div>
          <popper-content #tooltipcontent>
            <div style="text-align: center;background-color:white;"><p class="text-success mb-0">Copied!</p></div>
          </popper-content>
          <button class="btn btn-secondary btn-spacing" *ngIf="showTabs" disabled>Submit Photo</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex mb-3" style="justify-content: space-between;">
          <button class="btn btn-secondary btn-spacing" *ngIf="showTabs" (click)="submitCorrection()">Submit A Correction</button>
          <p *ngIf="!showTabs"></p>
          <button class="btn btn-secondary btn-spacing" *ngIf="showTabs" (click)="showSummary()">Show Summary</button>
          <button class="btn btn-primary" *ngIf="userIsLoggedIn" (click)="logout()">Logout</button>
          <button class="btn btn-primary" *ngIf="!userIsLoggedIn" (click)="login()">Login</button>
        </div>
      </div>
      <app-breadcrumb></app-breadcrumb>
      <div *ngIf="!showTabs" class="row">
        <div class="col-md-12 mx-auto">
          <div class="grid_12">
            <header>
              <h4 style="margin-bottom: 0;">Search For A Dog</h4>
            </header>
          </div>
          <div id="search-panel" class="collapse search-panel mb-3 show">
            <div class="panel panel-default  card p-3">
              <div class="panel-heading">
                <p class="lead">Enter your search terms below. As you type, the search results will be returned in the table below.</p>
                <p class="mt-2"><strong>Search Hints:</strong></p>
                <div class="row p-1">
                  <div class="col-md-12">
                    <ul>
                      <li>Searching any part of the dog's name will search for both call names and registered names. You must use a minimum of three letters for this search.</li>
                      <li>If your search is not returning the dog you are looking for, try searching for a shorter part of the name (minimum 3 letters).</li>
                      <li>If your dog's name has an apostrophe in it, it is safe to enter it in the search field since this will find all dogs with and without the apostrophe.</li>
                      <li>Having trouble? Please check the <a routerLink="/faqs">Frequently Asked Questions page</a> for more information</li>
                      <li>Need more assistance? Please contact us at <a href="mailto:help@ncadatabase.org">help@ncadatabase.org</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="row mt-2">
                  <form [formGroup]="dogForm" id="search-form" class="input-group" role="form">
                    <div class="input-group-prepend">
                      <select id="qualifier" name="qualifier" class="form-control" formControlName="qualifier" style="padding: 1rem 0.75rem;"
                              (change)="changeQueryType($event)">
                        <option value="contain" selected>Contains:</option>
                        <option value="startsWith">Starts with:</option>
                      </select>
                    </div>
                    <div class="form-floating">
                      <input type="text" id="search_name" name="search_name" class="form-control" placeholder="Dog name or registration"
                             formControlName="search_name">
                      <label for="search_name">Name or reg ID {{ dropdownValue }} </label>
                    </div>
                  </form>
                </div>
                <div class="row mt-1 ps-2">
                  <div class="col-md-4">
                    <p><strong>Found:</strong> {{ totalDogsFound }} dogs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary" data-bs-toggle="collapse" data-bs-target="#search-panel" (click)="searchPanelChange()">
            <span class="glyphicon glyphicon-search">{{ searchPanelButtonName }}</span>
          </button>
        </div>
      </div>
      <div *ngIf="!showTabs" class="row mt-2">
        <div class="col-md-12 mx-auto table-responsive">
          <h2>List of all dogs found matching your parameters</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mx-auto table-responsive">
          <div class="overlay" *ngIf="showDogTableSpinner && !showTabs">
            <div class="spinner-wrapper">
              <app-spinner></app-spinner>
            </div>
          </div>
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" [ngClass]="loading" #dtTable
                 class="table table-sm table-condensed table-hover table-bordered display pl-2 pr-2 py-0 w-100"
                 style="cursor: pointer;">

          </table>
        </div>
      </div>

      <div *ngIf="showTabs" class="row secBackground">
        <div class="col-md-12 mx-auto">
          <!-- Tabs nav -->
          <ul class="d-block d-sm-none d-md-none nav nav-pills nav-pills-custom">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{ currentTabName }}</a>
              <div class="dropdown-menu">
                <a class="dropdown-item active" href="#"  id="v-pop-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" aria-controls="v-pills-profile" aria-selected="true">Profile</a>
                <a class="dropdown-item" href="#"  id="v-pop-pedigree-tab" data-bs-toggle="pill" data-bs-target="#v-pills-pedigree" aria-controls="v-pills-pedigree" aria-selected="false">Pedigree</a>
                <a class="dropdown-item" href="#"  id="v-pop-offspring-tab" data-bs-toggle="pill" data-bs-target="#v-pills-offspring" aria-controls="v-pills-offspring" aria-selected="false">Offspring</a>
                <a class="dropdown-item" href="#"  id="v-pop-siblings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-siblings" aria-controls="v-pills-siblings" aria-selected="false">Siblings</a>
                <a class="dropdown-item" href="#"  id="v-pop-health-tab" data-bs-toggle="pill" data-bs-target="#v-pills-health" aria-controls="v-pills-health" aria-selected="false">Health</a>
                <a class="dropdown-item" href="#"  id="v-pop-awards-tab" data-bs-toggle="pill" data-bs-target="#v-pills-awards" aria-controls="v-pills-awards" aria-selected="false">Awards</a>
              </div>
            </li>
          </ul>

        </div>
      </div>
      <div *ngIf="showTabs" class="row">
        <div class="col-md-12">
          <!-- Tabs content -->
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade shadow rounded bg-white show active p-2" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
              <app-profile (tabSelected)="tabSelected($event)" [dog]="selectedDogInfo" [pedigree]="selectedDogPedigree"></app-profile>
            </div>

            <div class="tab-pane fade shadow rounded bg-white p-2" id="v-pills-pedigree" role="tabpanel" aria-labelledby="v-pills-pedigree-tab">
              <app-pedigree (tabSelected)="tabSelected($event)" #pedigreeComp [dog]="selectedDogInfo" [pedigree]="selectedDogPedigree.pedigree"></app-pedigree>
            </div>

            <div class="tab-pane fade shadow rounded bg-white p-2" id="v-pills-offspring" role="tabpanel" aria-labelledby="v-pills-offspring-tab">
              <app-offspring (tabSelected)="tabSelected($event)" [dog]="selectedDogInfo" [offspring]="selectedDogOffspring" [member]="userIsLoggedIn"></app-offspring>
            </div>

            <div class="tab-pane fade shadow rounded bg-white p-2" id="v-pills-siblings" role="tabpanel" aria-labelledby="v-pills-siblings-tab">
              <app-siblings (tabSelected)="tabSelected($event)" [dog]="selectedDogInfo" [siblings]="selectedDogSiblings" [member]="userIsLoggedIn"></app-siblings>
            </div>

            <div class="tab-pane fade shadow rounded bg-white p-2" id="v-pills-health" role="tabpanel" aria-labelledby="v-pills-health-tab">
              <app-health (tabSelected)="tabSelected($event)" [dog]="selectedDogInfo" [healths]="selectedDogHealth"></app-health>
            </div>

            <div class="tab-pane fade shadow rounded bg-white p-2" id="v-pills-awards" role="tabpanel" aria-labelledby="v-pills-awards-tab">
              <app-awards (tabSelected)="tabSelected($event)" [dog]="selectedDogInfo" [awards]="selectedDogAwards"></app-awards>
            </div>
          </div>
        </div>
      </div>
      <app-modal id="modal-search-error">
        <div class="modal-header">
          <h5 class="modal-title">Server Error</h5>
          <button type="button" class="btn-close" (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
          <p>An error occurred while trying to retrieve the data for this dog.  Please try again.</p>
          <p>If this error continues to occur, please send an email to <a href="mailto:dan@dogfishsoftware.com">dan@dogfishsoftware.com</a> and provide the dog's name that was being searched.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeModal();">Close</button>
        </div>

      </app-modal>
      <app-modal-large id="modal-search-summary">
        <div class="modal-header">
          <h5 class="modal-title">Summary</h5>
          <button type="button" class="btn-close" (click)="closeLargeModal()"></button>
        </div>
        <div class="modal-body">
          <app-summary [dog]="selectedDogInfo" [offspring]="selectedDogOffspring" [member]="userIsLoggedIn" [siblings]="selectedDogSiblings" [healths]="selectedDogHealth" [awards]="selectedDogAwards" [pedigree]="selectedDogPedigree"></app-summary>
        </div>
      </app-modal-large>
    </div>
  </div>
</div>
