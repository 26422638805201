import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  loggedInEmail = '';

  menuItems = [
    { name: 'Dashboard', path: 'dashboard'},
    { name: 'Manage Dogs', path: 'dogs'},
    { name: 'Import Reports', path: 'reports'},
    { name: 'Manage System', path: 'system'}];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
              private router: Router,
              private tokenService: TokenStorageService) {
    const curUser = tokenService.getUser();
    if (curUser.hasOwnProperty('email')) {
      this.loggedInEmail = curUser.email;
    }
  }

  logout() {
    this.tokenService.signOut();
    this.router.navigate(['/', 'login'], { queryParams: {retUrl: 'admin'}});
  }
}
